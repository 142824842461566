import React from "react";
import { Button, Flex } from "antd";

const Dashboard = () => {
    return (
        <>
            <div className="page-content">
                <div className="page-center-div">Dashboard</div>
            </div>
        </>
    );
};

export default Dashboard;
