import React, { useEffect, useState } from "react";
import { Button, Modal, Input, List, ConfigProvider } from "antd";
import { CaretDownFilled, BankOutlined, CheckCircleFilled, CloseOutlined, SearchOutlined, RetweetOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import SimpleBar from "simplebar-react";
import "simplebar-react/dist/simplebar.min.css";
import Cookies from "js-cookie";
import CryptoJS from "crypto-js";

import { withProfile } from "../../../contexts/profileContext";

const secretKey = "L3j*8k!2O#dM9wQ4T$7eV6rX0fN1pG@5";

const MyCompany = ({ setValidatedProfile, validatedProfile }) => {
    const navigate = useNavigate();
    const [companys, setcompanys] = useState([]);
    const [filteredCompanies, setFilteredCompanies] = useState([]);
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [searchKeyword, setSearchKeyword] = useState("");

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        const filtered = companys.filter((company) => company.company_name.toLowerCase().includes(searchKeyword.toLowerCase()));
        setFilteredCompanies(filtered);
    }, [searchKeyword, companys]);

    const fetchData = async () => {
        try {
            let response;

            if (validatedProfile.userType === "P") {
                const userId = validatedProfile.userid;

                const data = {
                    userId: userId,
                };
                response = await axios.post(`${process.env.REACT_APP_API_URL}/company-data`, data, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                });
            } else {
                const userId = validatedProfile.userid;

                const data = {
                    userId: userId,
                };

                response = await axios.post(`${process.env.REACT_APP_API_URL}/companydata-bycompanyid`, data, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                });
            }

            const companyList = response.data.getCompanyDatas || [];
            setcompanys(companyList);
            setFilteredCompanies(companyList);

            if (!validatedProfile.companyId) {
                const firstCompany = companyList[0];
                if (firstCompany) {
                    const updatedProfile = {
                        ...validatedProfile,
                        companyId: firstCompany.company_id,
                        companyName: firstCompany.company_name,
                        companyState: firstCompany.company_state,
                    };
                    setValidatedProfile(updatedProfile);
                    try {
                        const encryptedProfile = CryptoJS.AES.encrypt(JSON.stringify(updatedProfile), secretKey).toString();
                        Cookies.set("validatedProfile", encryptedProfile, {
                            expires: 7, // Cookie expires in 7 days
                        });
                    } catch (error) {
                        console.error("Error encrypting profile data:", error);
                    }
                }
            }
        } catch (error) {
            console.error("Error:", error.message);
        }
    };

    const companyFefreshBtn = () => {
        fetchData();
    };
    const handleButtonClick = () => {
        setDropdownVisible(!dropdownVisible);
    };

    const ManageButtonClick = () => {
        setDropdownVisible(!dropdownVisible);
        navigate("/my-companies");
    };

    const hendelcompany = (companyId, companyName, company_state) => {
        const confirmed = window.confirm(`Are you sure you want to Change "${companyName}"?`);

        if (confirmed) {
            const updatedProfile = {
                ...validatedProfile,
                companyId: companyId,
                companyName: companyName,
                companyState: company_state,
            };
            setValidatedProfile(updatedProfile);
            setDropdownVisible(!dropdownVisible);
        }
    };

    const handleCancel = () => {
        setDropdownVisible(false);
    };

    const handleSearch = (e) => {
        setSearchKeyword(e.target.value);
    };

    return (
        <>
            <ConfigProvider
                theme={{
                    token: {
                        colorPrimary: "#0bb197",
                    },
                }}
            >
                <span onClick={handleButtonClick} className="company-icon header-icon-pd">
                    <p className="mycompany-icon-btn">
                        {"|"}
                        <span className="mycompany-name-text">
                            {validatedProfile.companyName ? validatedProfile.companyName.toUpperCase() : "Add Company"}
                            {validatedProfile.companyName && <CaretDownFilled className="header-admin-icon w-5" />}
                        </span>
                        {"|"}
                    </p>
                </span>

                <Modal
                    className="companylist-model"
                    open={dropdownVisible}
                    okButtonProps={{ style: { display: "none" } }}
                    onCancel={handleCancel}
                >
                    <div className="company-dropdown-outer">
                        <div className="company-dropdown bg-white">
                            <div className="dropdown-body">
                                <div className="dropdown-body-content">
                                    <div className="dropdown-body-header">
                                        <div className="company_heading_andClose">
                                            <span className="close_icon_btn" onClick={handleCancel}>
                                                <CloseOutlined />
                                            </span>
                                            <p>My Company</p>
                                            <RetweetOutlined className="company_refresh_btn" onClick={companyFefreshBtn} />
                                        </div>

                                        <Input
                                            className="search_company_input"
                                            type="text"
                                            placeholder="Search company"
                                            value={searchKeyword}
                                            onChange={handleSearch}
                                            prefix={<SearchOutlined />}
                                        />
                                        <Button type="primary" className="company_manage_btn" onClick={ManageButtonClick}>
                                            Manage
                                        </Button>
                                    </div>
                                    <SimpleBar style={{ maxHeight: "calc(90vh - 50px)" }}>
                                        {filteredCompanies.length > 0 ? (
                                            <List
                                                size="large"
                                                dataSource={filteredCompanies}
                                                renderItem={(item) => (
                                                    <List.Item
                                                        className={validatedProfile.companyId === item.company_id ? "active" : ""}
                                                        onClick={() =>
                                                            hendelcompany(item.company_id, item.company_name, item.company_state)
                                                        }
                                                    >
                                                        <div className="company-li-div">
                                                            <BankOutlined className="header-company-logo" />
                                                            <a className="company-select-icon">{item.company_name.toUpperCase()}</a>
                                                        </div>
                                                        {validatedProfile.companyId === item.company_id ? (
                                                            <CheckCircleFilled className="text-success" />
                                                        ) : null}
                                                    </List.Item>
                                                )}
                                            />
                                        ) : (
                                            <>
                                                <p className="text-center text-danger">No companies available</p>
                                                <p className="text-center text-danger">Add companies from Tax-link Tally Connector App</p>
                                            </>
                                        )}
                                    </SimpleBar>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="close_button_div"></div>
                </Modal>
            </ConfigProvider>
        </>
    );
};

export default withProfile(MyCompany);
