import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { DeleteOutlined, EllipsisOutlined } from "@ant-design/icons";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { Button, Dropdown } from "antd";
import { withProfile } from "../../contexts/profileContext";
import { withRole } from "../../contexts/roleContext";
import Spinner from "../../components/Common/Spinner/Spinner";
import { AgGridReact } from "ag-grid-react";
import withRouter from "../../components/Common/withRouter";

const TableAll = ({ searchKeyword, validatedProfile, role, urlPathName, mainTableData, fetchData, isLoading, setIsLoading }) => {
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [usersData, setUsersdData] = useState([]);
    const [colDefs, setColDefs] = useState([]);

    const navigate = useNavigate();
    const { pathnameWithSlash: currentPath, pathWithoutSlash, pathOnlyName } = urlPathName;

    const masterPaths = [
        "/autosell",
        "/master",
        "/ledgermaster",
        "/item",
        "/ruelist",
        "/leadgergroupnames",
        "/ledgernames",
        "/stockgroup",
        "/stockitem",
        "/unit",
        "/vouchertype",
    ];
    const deleteButtonInActionColumn = ["banking", "sales/excel", "item/excel", "ledgerbulk", "autosale", "autoexpenses", "purchase"];

    useEffect(() => {
        if (mainTableData) {
            fetchTableData();
        }
    }, [mainTableData, validatedProfile?.companyId, searchKeyword, pathWithoutSlash]);

    useEffect(() => {
        if (searchKeyword) {
            searchKeyword = "";
        }
    }, [pathWithoutSlash]);

    const fetchTableData = async () => {
        try {
            if (mainTableData) {
                const processedData = processDatas(mainTableData);

                setColDefs(generateColumns(processedData));
                if (searchKeyword && (pathWithoutSlash === "item" || pathWithoutSlash === "ledgermaster")) {
                    const filtered = processedData.filter((row) => row.name.toLowerCase().includes(searchKeyword.toLowerCase()));
                    setUsersdData(filtered);
                } else {
                    setUsersdData(processedData?.reverse());
                }
            }
        } catch (error) {
            console.error("Error fetching table data:", error);
        }
    };

    const processDatas = (data) => {
        return data[pathWithoutSlash]?.map((obj) =>
            Object.entries(obj).reduce((acc, [key, value]) => {
                acc[key.replace(/_/g, " ")] = value;
                return acc;
            }, {})
        );
    };

    const generateColumns = (data) => {
        if (!data || !data.length) return [];

        const isBankingOrSales = deleteButtonInActionColumn.includes(pathWithoutSlash);
        const indexColumns = [
            {
                headerName: "",
                field: "",
                width: 35,
                suppressHeaderMenuButton: true,
                headerCheckboxSelection: true, // Allow header checkbox for bulk selection
                checkboxSelection: true, // Display checkboxes in the rows
                showDisabledCheckboxes: true,
            },

            {
                headerName: "Sr No",
                field: "Sr No",
                width: 70,
                suppressMenu: true,
                headerClass: "custom-header-center",
                valueGetter: (params) => params.node.rowIndex + 1, // Generates row index
                cellStyle: { display: "flex", justifyContent: "center", alignItems: "center" }, // Center cell content
            },
        ].filter(Boolean);
        // console.log("data", data);

        const fields = Object.keys(data[0]);

        const includedColumns = getIncludedColumns(pathWithoutSlash);
        const columns = fields
            .filter((field) => includedColumns.includes(field.toLowerCase()) && field !== "status")
            .map((field) => ({
                field: field.replace(/_/g, " "),
                filter: false,
                flex: getFlexValue(field, pathWithoutSlash),
                cellRenderer: (params) => (params.value !== null ? params.value : "--"),
                cellStyle: () =>
                    ["total", "pending", "save", "synced", "statement date", "synced date"].includes(field.toLowerCase())
                        ? { display: "flex", justifyContent: "center" }
                        : {},
                headerClass: (params) =>
                    ["total", "pending", "save", "synced", "statement date", "synced date"].includes(field.toLowerCase())
                        ? "custom-header-center" // Add this class for specific columns
                        : "",
                suppressMenu: true,
            }));

        const statusColumn = {
            headerName: "Status",
            field: "status",
            width: 120,
            cellRenderer: (params) => <span className={params.value}>{params.value}</span>,
            headerClass: "custom-header-center",
            cellStyle: { textAlign: "center" },
            suppressMenu: true,
        };
        const actionColumn = {
            headerName: "Action",
            field: "delete",
            suppressHeaderMenuButton: true,
            width: 80,
            headerClass: "custom-header-center",
            cellStyle: { textAlign: "center" },
            cellRenderer: (params) => {
                const isDeletable = role && ["D", "F"].includes(role[currentPath]) && params.data.status !== "Complete";

                const buttonClass = isDeletable
                    ? "text-danger maintable-deleterow Table-deleterow hide_btn_class"
                    : "text-secondary maintable-deleterow Table-deleterow hide_btn_class";

                const items = [
                    {
                        key: "delete1",
                        label: (
                            <Button
                                className={buttonClass}
                                type="danger"
                                disabled={!isDeletable || params.data.status === "Complete"}
                                onClick={(event) => {
                                    event.stopPropagation();
                                    if (isDeletable) deleteSingleRow(params.data);
                                }}
                            >
                                <DeleteOutlined />
                            </Button>
                        ),
                    },
                ];

                return (
                    <>
                        <Button
                            className={buttonClass}
                            type="danger"
                            disabled={!isDeletable || params.data.status === "Complete"}
                            onClick={(event) => {
                                event.stopPropagation();
                                if (isDeletable) deleteSingleRow(params.data);
                            }}
                        >
                            <DeleteOutlined />
                        </Button>
                        <Button className="action_dropdown_icon" type="danger">
                            <Dropdown
                                menu={{
                                    items,
                                }}
                                placement="bottomRight"
                                arrow
                                trigger={["click"]}
                            >
                                <a onClick={(e) => e.preventDefault()}>
                                    <EllipsisOutlined
                                        style={{
                                            fontSize: "24px",
                                            color: "#1890ff",
                                            transform: "rotate(90deg)",
                                        }}
                                    />
                                </a>
                            </Dropdown>
                        </Button>
                    </>
                );
            },
        };
        return [...indexColumns, ...columns, statusColumn, ...(isBankingOrSales ? [actionColumn] : [])];
    };

    const getIncludedColumns = (path) => {
        switch (path) {
            case "banking":
                return ["name", "bank name", "statement date", "synced date", "pending", "save", "total", "synced", "suggestion", "status"];
            case "sales/excel":
                return ["name", "type", "statement date", "synced date", "pending", "save", "total", "synced", "suggestion", "status"];
            case "ledgerbulk":
                return ["name", "pending", "save", "total", "synced", "suggestion", "status"];
            case "autosale":
                return ["name of autosale", "sr no", "total items"];
            case "ledgermaster":
                return ["name", "under", "partygstin"];
            case "purchase":
                return ["name", "statement date", "synced date", "pending", "save", "total", "synced", "status"];
            case "item/excel":
                return ["srno", "name", "under", "gstno", "integrated tax", "status"];
            case "item":
                return ["name"];
            case "autoexpenses":
                return ["name", "total items"];
            default:
                return [];
        }
    };

    const getFlexValue = (field, path) => {
        const flexMap = {
            banking: {
                name: 4,
                "bank name": 1.7,
                "statement date": 1.2,
                "synced date": 1.2,
                pending: 0.7,
                save: 0.7,
                total: 0.7,
                synced: 0.7,
                suggestion: 0.7,
                status: 0.7,
            },
            "sales/excel": {
                name: 3,
                "statement date": 1,
                "synced date": 1,
                pending: 0.7,
                type: 0.5,
                save: 0.7,
                total: 0.7,
                synced: 0.7,
                suggestion: 1,
                status: 1,
            },
            purchase: {
                name: 3,
                "statement date": 1,
                "synced date": 1,
                pending: 0.7,
                type: 0.5,
                save: 0.7,
                total: 0.7,
                synced: 0.7,
                suggestion: 1,
                status: 1,
            },
            ledgerbulk: { name: 3, pending: 1, save: 1, total: 1, synced: 1, suggestion: 1, status: 1 },
            autosale: { "name of autosale": 3, "sr no": 2, "total items": 2 },
            ledgermaster: { name: 3, under: 2, partygstin: 2, "integrated tax": 2 },
        };
        return flexMap[path]?.[field.toLowerCase()] || 1;
    };

    const deleteSingleRow = async (rowData) => {
        let deleteFile = window.confirm(`Are you want to delete "${rowData.name}" file?`);
        if (deleteFile) {
            const deleteID = masterPaths.includes(currentPath) ? rowData.id : rowData.srno;
            try {
                const { status } = await axios.post(`${process.env.REACT_APP_API_URL}/deleteData`, {
                    userId: validatedProfile.userType === "S" ? validatedProfile.parentid : validatedProfile.userid,
                    companyId: validatedProfile.companyId,
                    tablename: pathOnlyName === "item" ? "stockitem" : pathOnlyName,
                    deleteID: [deleteID],
                });
                if (status === 200) {
                    fetchData();
                    fetchTableData();
                }
            } catch (error) {
                console.error("Error deleting row:", error);
            }
        }
    };

    const deleteSelectedRows = async () => {
        let deleteFile = window.confirm(`Are you want to delete all selected file?`);
        if (deleteFile) {
            try {
                const { status } = await axios.post(`${process.env.REACT_APP_API_URL}/deleteData`, {
                    userId: validatedProfile.userType === "S" ? validatedProfile.parentid : validatedProfile.userid,
                    companyId: validatedProfile.companyId,
                    tablename: pathOnlyName,
                    deleteID: selectedRowKeys,
                });
                if (status === 200) {
                    fetchData();
                    fetchTableData();
                }
            } catch (error) {
                console.error("Error deleting selected rows:", error);
            }
        }
    };

    const renderDeleteButton = () =>
        selectedRowKeys.length >= 2 &&
        role &&
        ["D", "F"].includes(role[currentPath]) && (
            <Button type="primary" className="deleteSelectedRow" onClick={deleteSelectedRows}>
                Delete Selected Row
            </Button>
        );

    const onSelectionChanged = (params) => {
        const selectedIds = params.api.getSelectedRows().map((row) => (masterPaths.includes(currentPath) ? row.id : row.srno));
        setSelectedRowKeys(selectedIds);
    };

    const isRowSelectable = useCallback((params) => {
        return params?.data?.status === "Pending";
    }, []);

    return (
        <>
            <Spinner isSpinner={isLoading} />
            <div className="ag-theme-alpine ag-main-table themeQuartz">
                <AgGridReact
                    className="main_table_ag"
                    rowData={usersData}
                    isRowSelectable={isRowSelectable}
                    columnDefs={colDefs}
                    pagination
                    rowSelection="multiple"
                    paginationPageSize={20}
                    paginationPageSizeSelector={[10, 20, 50, 100]}
                    gridOptions={generateColumns}
                    onCellClicked={(params) => {
                        if (params.colDef.field !== "delete" && !masterPaths.includes(currentPath)) {
                            navigate("/transactionstable", {
                                state: {
                                    key: params.data.srno,
                                    pathWithoutSlash,
                                    currentPath,
                                    pathOnlyName,
                                },
                            });
                        }
                    }}
                    onSelectionChanged={onSelectionChanged}
                    overlayNoRowsTemplate="<span>No data available</span>"
                />
                {renderDeleteButton()}
            </div>
        </>
    );
};

export default withProfile(withRole(withRouter(TableAll)));
