import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./styles/index.css"; //sass css
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import Providers from "./contexts";
import { BrowserRouter } from "react-router-dom";
import { GoogleOAuthProvider } from "@react-oauth/google";

import "ag-grid-enterprise";
import { LicenseManager } from "ag-grid-enterprise";
import { ConfigProvider } from "antd";
LicenseManager.setLicenseKey(
    "[TRIAL]_this_{AG_Charts_and_AG_Grid}_Enterprise_key_{AG-074442}_is_granted_for_evaluation_only___Use_in_production_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_purchasing_a_production_key_please_contact_info@ag-grid.com___You_are_granted_a_{Single_Application}_Developer_License_for_one_application_only___All_Front-End_JavaScript_developers_working_on_the_application_would_need_to_be_licensed___This_key_will_deactivate_on_{28 February 2025}____[v3]_[0102]_MTc0MDcwMDgwMDAwMA==bb2688d270ed69f72a8ba59760c71424"
);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
        <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
            <Providers>
                <BrowserRouter>
                    <ConfigProvider // button input hover and active defoult color from here
                        theme={{
                            token: {
                                colorPrimary: "#0bb197",
                            },
                        }}
                    >
                        <App />
                    </ConfigProvider>
                </BrowserRouter>
            </Providers>
        </GoogleOAuthProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
