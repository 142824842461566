import { GeneralProvider } from "./generalContext";
import { ProfileProvider } from "./profileContext";
import { RoleProvider } from "./roleContext";

const Providers = ({ children }) => {
    return (
        <GeneralProvider>
            <ProfileProvider>
                <RoleProvider>{children}</RoleProvider>
            </ProfileProvider>
        </GeneralProvider>
    );
};

export default Providers;
