import { Button, Input, Modal, Select, Table } from "antd";
import React, { useState, useEffect } from "react";
import { DeleteOutlined, EditOutlined, PlusCircleOutlined, SaveOutlined, CloseOutlined } from "@ant-design/icons";
import axios from "axios";

const { Option } = Select;

const SpliteTable = ({
    splitModalOpen,
    validatedProfile,
    fetchSubTableData,
    setSplitModalOpen,
    pathPlusData,
    ledgeroption,
    selectedSlpitIdData,
    viewAll,
}) => {
    const [initialDataLedger, setInitialDataLedger] = useState([]);
    const [updateLedger, setUpdateLedger] = useState([]);
    const [selectedLedger, setSelectedLedger] = useState(null);
    const [amount, setAmount] = useState("");
    const [totalAmount, setTotalAmount] = useState(0);
    const [remainingAmount, setRemainingAmount] = useState(0);
    const [editingKey, setEditingKey] = useState("");
    const [amountKey, setAmountKey] = useState("");

    useEffect(() => {
        let key;
        if (splitModalOpen && selectedSlpitIdData) {
            key = selectedSlpitIdData.credit > 0 ? "credit" : "debit";
            setAmountKey(key);
            setTotalAmount(parseFloat(selectedSlpitIdData[key]) || 0);
            setRemainingAmount(parseFloat(selectedSlpitIdData[key]) || 0);
            getSplitDataFromDatabase(key);
        }
    }, [splitModalOpen, selectedSlpitIdData]);

    useEffect(() => {
        const totalAddedAmount = updateLedger.reduce((total, item) => total + parseFloat(item.amount), 0);
        setRemainingAmount(totalAmount - totalAddedAmount);
    }, [updateLedger, totalAmount]);

    const updateToDatabase = () => {
        let newData = [];

        if (initialDataLedger.length > 0) {
            newData = updateLedger.filter((item) => {
                const initialItem = initialDataLedger.find((initial) => initial.id === item.id);
                return !initialItem; // If no matching row is found, add the row to newData
            });
        } else {
            newData = updateLedger;
        }

        const payload = {
            userId: validatedProfile.userType === "S" ? validatedProfile.parentid : validatedProfile.userid,
            companyId: validatedProfile.companyId,
            tablename: pathPlusData,
            data: newData.map((item) => {
                const { amount, ...rest } = item;
                return {
                    ...rest,
                    [amountKey]: amount, // Use correct amountKey (credit or debit) in payload
                };
            }),
        };
        // console.log("payload", payload);
        axios
            .post(`${process.env.REACT_APP_API_URL}/splitRowdata`, payload)
            .then((response) => {
                if (response.status === 200) {
                    setSplitModalOpen(false);
                    setUpdateLedger([]);
                    fetchSubTableData();
                    viewAll();
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    const getSplitDataFromDatabase = (amountKey) => {
        const payload = {
            userId: validatedProfile.userType === "S" ? validatedProfile.parentid : validatedProfile.userid,
            companyId: validatedProfile.companyId,
            data: [selectedSlpitIdData.id],
        };
        // console.log("payload", payload);
        axios
            .post(`${process.env.REACT_APP_API_URL}/getDataOfSplitRow`, payload)
            .then((response) => {
                if (response.status === 200) {
                    const splitData = response.data.dataOfSplitRow || [];
                    const initialData = splitData.reduce((acc, item, index) => {
                        if (index === 0 && item.issplit === "NO") {
                            return acc;
                        }

                        const newItem = {
                            key: `${index}`,
                            srno: splitData[index].srno,
                            ledger: splitData[index].ledger,
                            amount: parseFloat(splitData[index][amountKey]) || 0, // Amount based on credit/debit
                            id: item.id,
                            descriptions: item.description,
                        };

                        acc.push(newItem);
                        return acc;
                    }, []);
                    setInitialDataLedger(initialData);
                    setUpdateLedger(initialData);
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    let i = 1;
    const addSplitItem = () => {
        if (selectedLedger && amount) {
            if (remainingAmount - amount >= 0) {
                const newItem = {
                    key: i,
                    srno: selectedSlpitIdData.srno,
                    ledger: selectedLedger,
                    amount: parseFloat(amount),
                    id: selectedSlpitIdData.id,
                    descriptions: selectedSlpitIdData.description,
                };
                setUpdateLedger([...updateLedger, newItem]);
                setSelectedLedger(null);
                setAmount("");
            } else {
                alert("You can not add amount more than total");
            }
        }
        i++;
    };

    const handleEdit = (record) => {
        setEditingKey(record.key);
        setSelectedLedger(record.ledger);
        setAmount(record.amount);
    };

    const saveEdit = (key) => {
        const newData = [...updateLedger];
        const index = newData.findIndex((item) => item.key === key);
        if (index > -1) {
            const item = newData[index];
            newData.splice(index, 1, { ...item, ledger: selectedLedger, amount: parseFloat(amount) });
            setUpdateLedger(newData);
            setEditingKey("");
            setSelectedLedger(null);
            setAmount("");
        }
        const updateLedgerItem = [newData.find((initial) => initial.key === key)];

        const payload = {
            userId: validatedProfile.userType === "S" ? validatedProfile.parentid : validatedProfile.userid,
            companyId: validatedProfile.companyId,
            tablename: pathPlusData,
            data: updateLedgerItem.map((item) => {
                const { amount, ...rest } = item;
                return {
                    ...rest,
                    [amountKey]: amount, // Use correct amountKey (credit or debit) in payload
                };
            }),
        };
        console.log("payload", payload);

        axios
            .post(`${process.env.REACT_APP_API_URL}/changeUpdateSplitRow`, payload)
            .then((response) => {
                // console.log("response", response);
                if (response.status === 200) {
                    setUpdateLedger([]);
                    // fetchSubTableData();
                    // viewAll();
                    // setSplitModalOpen(false);
                    getSplitDataFromDatabase(amountKey);
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    const cancelEdit = () => {
        setEditingKey("");
        setSelectedLedger(null);
        setAmount("");
    };

    const handleDelete = (key) => {
        const deleteId = updateLedger.filter((item) => item.key === key);

        axios
            .post(`${process.env.REACT_APP_API_URL}/deleteData`, {
                userId: validatedProfile.userType === "S" ? validatedProfile.parentid : validatedProfile.userid,
                companyId: validatedProfile.companyId,
                tablename: "bankingdata",
                deleteID: [{ id: deleteId[0].id }],
            })
            .then((response) => {
                if (response.status === 200) {
                    getSplitDataFromDatabase(amountKey);
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });

        const newData = updateLedger.filter((item) => item.key !== key);
        setUpdateLedger(newData);
    };

    const columns = [
        {
            title: "Sr No",
            dataIndex: "key",
            key: "key",
            width: "10%",
        },
        {
            title: "Ledger",
            dataIndex: "ledger",
            key: "ledger",
            render: (text, record) =>
                editingKey === record.key ? (
                    <Select showSearch onChange={(value) => setSelectedLedger(value)} value={selectedLedger} style={{ width: 200 }}>
                        {ledgeroption &&
                            ledgeroption.map((item) => (
                                <Select.Option key={item} value={item}>
                                    {item}
                                </Select.Option>
                            ))}
                    </Select>
                ) : (
                    text
                ),
            width: "53%",
        },
        {
            title: amountKey.charAt(0).toUpperCase() + amountKey.slice(1), // Dynamically set column title to "Credit" or "Debit"
            key: "amount",
            dataIndex: "amount",
            render: (text, record) =>
                editingKey === record.key ? <Input value={amount} onChange={(e) => setAmount(e.target.value)} /> : text,
            width: "17%",
        },
        {
            title: "Action",
            key: "action",
            render: (_, record) =>
                record.key !== "total" && (
                    <p>
                        {editingKey === record.key ? (
                            <>
                                <Button className="splitMenu-model-ED-btn" type="link" onClick={() => saveEdit(record.key)}>
                                    <SaveOutlined />
                                </Button>
                                <Button className="splitMenu-model-ED-btn" type="link" onClick={cancelEdit}>
                                    <CloseOutlined />
                                </Button>
                            </>
                        ) : (
                            <>
                                <Button className="splitMenu-model-ED-btn" type="link" onClick={() => handleEdit(record)}>
                                    <EditOutlined />
                                </Button>
                                <Button className="splitMenu-model-ED-btn" type="link" onClick={() => handleDelete(record.key)}>
                                    <DeleteOutlined className="text-danger" />
                                </Button>
                            </>
                        )}
                    </p>
                ),
            width: "20%",
        },
    ];

    const totalRow = {
        key: "total",
        srno: "",
        ledger: "Total",
        amount: updateLedger.reduce((total, item) => total + parseFloat(item.amount), 0).toFixed(2),
    };

    return (
        <Modal className="splitMenu-model" open={splitModalOpen} onCancel={() => setSplitModalOpen(false)} footer={null} width={600}>
            <div className="splitMenu-outer">
                <div className="splitMenu-inner">
                    <div className="splitMenu-header">
                        <p>{splitModalOpen ? selectedSlpitIdData.description : ""}</p>
                    </div>
                    <div className="splitMenu-body">
                        <div className="body-split-Topbar">
                            <div className="Topbar-Amount d-flex justify-content-between">
                                <div className="Topbar-Total">
                                    <p>Total Amount = {totalAmount.toFixed(2)}</p>
                                </div>
                                <div className="Topbar-Remaining">
                                    <p>Remaining Amount = {remainingAmount.toFixed(2)}</p>
                                </div>
                            </div>
                            <div className="Topbar-input d-flex">
                                <div className="Topbar-input-SelectLedger pr-2">
                                    <span>Select Ledger</span>
                                    <Select showSearch onChange={(value) => setSelectedLedger(value)} value={selectedLedger}>
                                        {ledgeroption &&
                                            ledgeroption.map((item) => (
                                                <Select.Option key={item} value={item}>
                                                    {item}
                                                </Select.Option>
                                            ))}
                                    </Select>
                                </div>
                                <div className="Topbar-input-Amount pl-2">
                                    <span>Amount</span>
                                    <Input placeholder="Enter amount" value={amount} onChange={(e) => setAmount(e.target.value)} />
                                </div>
                                <div className="Topbar-Button pl-2">
                                    <Button type="primary" icon={<PlusCircleOutlined />} onClick={addSplitItem}>
                                        Add
                                    </Button>
                                </div>
                            </div>
                        </div>
                        <Table
                            columns={columns}
                            dataSource={[...updateLedger, totalRow]}
                            pagination={false}
                            style={{ margin: "0px 15px 10px", border: "1px solid #e5e5e5" }}
                        />
                    </div>

                    <div className="splitMenu-footer">
                        {/* <Button key="back" type="primary" danger onClick={() => setUpdateLedger([])}>
                            Remove All
                        </Button> */}
                        <div></div>
                        <span>
                            <Button onClick={() => setSplitModalOpen(false)}>Cancel</Button>
                            <Button className="split-add-btn" type="primary" onClick={updateToDatabase}>
                                Save
                            </Button>
                        </span>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default SpliteTable;
