import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { PlusSquareFilled, PlusOutlined, FolderAddOutlined, SwapOutlined } from "@ant-design/icons";
import { Dropdown, Menu } from "antd";
import { withProfile } from "../../../contexts/profileContext";
import { GeneralContext } from "../../../contexts/generalContext";
import { withRole } from "../../../contexts/roleContext";

import pdfReader, { xlsxReader } from "./../../Pdf/Pdf";

import axios from "axios";

import Spinner from "../../../components/Common/Spinner/Spinner";
import BankingAll from "../../../components/Pages/Model/BamkingModelAll/BankingAll";
import AutoSaleForm from "../../../components/Pages/Model/AutoSaleModel/autoSaleForm";
import QuickcreateModel from "../../../components/Pages/Model/ledgerMaster&itemMasterModel/QuickcreateModel";
import withRouter from "../../../components/Common/withRouter";
import AutoExpensesForm from "../../../components/Pages/Model/AutoExpensesModel/autoExpensesForm";
import TransectionAll from "../../../components/Pages/Model/TransectionModelAll/TransectionAll";
import processImageForText from "../../ImageToText";

const Quickcreate = ({ validatedProfile, roleWithoutSlash, urlPathName, fetchData }) => {
    const [dropdownVisible, setDropdownVisible] = useState(false);

    const { opencomponent, modal2Open, setname, name } = useContext(GeneralContext);

    const [files, setFiles] = useState(null);

    const [selectedBankName, setSelectedBankName] = useState("");
    const [selectedDataArrayBank, setSelectedDataArrayBank] = useState(null);
    const [excelType, setExcelType] = useState("Single");

    // const location = useLocation();

    const currentPath = urlPathName.pathnameWithSlash;
    const pathWithoutSlash = urlPathName.pathWithoutSlash;
    const pathOnlyName = urlPathName.pathOnlyName;

    const [isSpinner, setIsSpinner] = useState(false); // New state for spinner

    const handleButtonClick = () => {
        setDropdownVisible(!dropdownVisible);
    };

    const navigate = useNavigate();

    const showcomponent = (value) => {
        const firstPart = value.split("/")[0];
        setname(firstPart);
        navigate("/" + value);
        opencomponent(value);
        setDropdownVisible(false);
    };

    const handleMenuClick = (e) => {
        setDropdownVisible(false);
    };

    const handleChange = (e) => {
        setFiles(e);
    };

    const uploadToDatabase = async (filename, json) => {
        try {
            const userId = validatedProfile.userType === "S" ? validatedProfile.parentid : validatedProfile.userid;
            const companyId = validatedProfile.companyId;

            const getData = (type, extraFields = {}) => ({
                userId,
                companyId,
                [`${type}data`]: json,
                [type]: [{ name: filename, ...extraFields }],
            });

            let data;

            if (pathWithoutSlash === "item") {
                data = getData("item");
            } else if (pathWithoutSlash === "ledgerbulk") {
                data = getData("ledgerbulk");
            } else if (pathWithoutSlash === "banking" && selectedDataArrayBank) {
                data = getData("banking", { Bank_Name: selectedBankName });

                const requiredColumns = ["date", "description", "vchtype", "cheque_no", "ledger", "debit", "credit", "balance"];

                let newData = data.bankingdata.map((row) => {
                    // Create a new object with only the required columns
                    return requiredColumns.reduce((filteredRow, key) => {
                        if (key in row) {
                            filteredRow[key] = row[key];
                        }
                        return filteredRow;
                    }, {});
                });

                newData = newData.map(({ credit, ...row }) => {
                    const creditValue = Number(credit) || 0;

                    return {
                        ...row,
                        credit: creditValue,
                        vchtype: creditValue > 0 ? "Receipt" : "Payment",
                    };
                });

                data.bankingdata = newData;
            } else {
                data = getData(pathOnlyName);
            }

            if (files[0]) {
                // data = { ...data, file: files[0] };
                data = { ...data };
            }

            console.log("data", data);

            if (data) {
                const response = await axios.post(`${process.env.REACT_APP_API_URL}/insertfileapi`, data, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                });
                if (response.status === 200) {
                    fetchData();
                    // console.log("response");
                    navigate("/transactionstable", {
                        state: { key: response.data.result.key, pathWithoutSlash, currentPath },
                    });
                }
                setIsSpinner(false);
            }
        } catch (error) {
            setIsSpinner(false);

            alert(error.response.data.message ? error.response.data.message : "Error in uploading data");
        }
    };

    const handleOkClick = () => {
        if (pathWithoutSlash !== "banking") {
            // opencomponent();
            handleConvert();
            return;
        }

        const isBankingDataValid = selectedBankName && selectedDataArrayBank;
        if (isBankingDataValid) {
            opencomponent();
            handleConvert();
            return;
        }

        // Show appropriate error messages
        if (!selectedBankName && !selectedDataArrayBank) {
            alert("Please Select Bank PDF and Bank");
        } else {
            if (!selectedDataArrayBank) alert("Please Select Bank PDF");
            if (!selectedBankName) alert("Please Select Bank");
        }
    };

    const handleBankOptionSelect = (bank) => {
        setSelectedDataArrayBank(bank);
    };
    // const [extractedText, setExtractedText] = useState("");

    const handleConvert = async () => {
        if (files) {
            setIsSpinner(true);
            if (files[0].name.includes(".pdf")) {
                const response = await pdfReader(files[0], selectedDataArrayBank);

                uploadToDatabase(files[0].name, response);
            }
            if (files[0].name.includes(".xlsx") || files[0].name.includes(".xls")) {
                try {
                    const json = await xlsxReader(files);
                    const file = files[0];

                    let hed;
                    const transactions = [];
                    json.forEach((item, key) => {
                        if (item[Object.keys(item)[0]] === "Txn Date") {
                            hed = Object.keys(item);
                        }

                        if (hed && item[Object.keys(item)[6]]) {
                            const transaction = {};
                            hed.forEach((key) => {
                                transaction[key] = item[key];
                            });

                            transactions.push(transaction);
                        }
                    });

                    let firstObject = transactions.shift();
                    const data = [];

                    transactions.forEach((obj) => {
                        const transaction = {};
                        for (let key in firstObject) {
                            if (firstObject[key] === "Value Date" || firstObject[key] === "Ref No./Cheque No.") {
                                continue;
                            }
                            if (firstObject[key] === "Txn Date") {
                                firstObject[key] = "DATE";
                            }
                            transaction[firstObject[key].trim()] = obj[key].trim();
                        }
                        data.push(transaction);
                    });

                    if (pathWithoutSlash === "sales/excel" || pathWithoutSlash === "purchase") {
                        const columnheading = json.length > 0 ? Object.keys(json[0]) : [];
                        const handleSalesNavigation = (fileName, json, columnheading, pathWithoutSlash, pathOnlyName, excelType) => {
                            setIsSpinner(false);

                            navigate("/mapping", {
                                state: { columnheading, pathWithoutSlash, pathOnlyName, json, fileName, excelType },
                            });
                        };

                        handleSalesNavigation(file.name, json, columnheading, pathWithoutSlash, pathOnlyName, excelType);
                    } else {
                        uploadToDatabase(file.name, hed !== undefined ? data : json);
                    }
                } catch (error) {
                    console.error("Error processing files:", error);
                }
            }
            if (files[0].name.includes(".jpeg") || files[0].name.includes(".jpg") || files[0].name.includes(".png")) {
                const image = files[0];

                if (!image) {
                    alert("Please select an image first!");
                    return;
                }
                setIsSpinner(false);
                // processImageForText(image);
                console.log("image", image);
                console.log("Extracted Text:", await processImageForText(image));

                // navigate("/imageinvoicetable", {
                //     state: { image, path: currentPath },
                // });
            }
            setIsSpinner(false);
            // setFiles(null);
        }
    };

    const handleCancel = () => {
        opencomponent();
    };

    const menuItems = [
        {
            title: "Bulk Import",
            icon: <FolderAddOutlined className="quickcreate_menu_icon" />,
            items: [
                { label: "Banking", path: "banking" },
                { label: "Sales", path: "sales/excel" },
                // { label: "Return", path: "sales-return" },
                // { label: "Purchase", path: "purchase/excel" },
                // { label: "Purchase Return", path: "purchase-return" },
                // { label: "Journal", path: "journal" },
                { label: "Master Ledger", path: "ledgermaster" },
                { label: "Master Item", path: "item" },
            ],
        },
        {
            title: "Transaction",
            icon: <SwapOutlined className="quickcreate_menu_icon" />,
            items: [
                { label: "Sales", path: "tc-sales" },
                { label: "Purchase", path: "tc-purchase" },
                // { label: "Payment", path: "tc-payment" },
                // { label: "Receipt", path: "tc-receipt" },
                // { label: "Contra", path: "tc-contra" },
            ],
        },
    ];

    const menu = (
        <div className="sub-quickcreate-inner">
            <Menu onClick={handleMenuClick} className="quickcreate-menu">
                {menuItems.map((section, index) => (
                    <div key={index} className="sub-ul">
                        <h6>
                            {section.icon}
                            {section.title}
                        </h6>
                        <div className="colm-flex">
                            <div className="colm">
                                {section.items.slice(0, Math.ceil(section.items.length)).map((item, idx) => {
                                    for (let rolekey in roleWithoutSlash) {
                                        if (item.path === rolekey && roleWithoutSlash[rolekey] !== null) {
                                            return (
                                                <p key={idx}>
                                                    <button onClick={() => showcomponent(item.path)}>
                                                        <PlusOutlined />
                                                        {item.label}
                                                    </button>
                                                </p>
                                            );
                                        }
                                    }
                                })}
                            </div>
                        </div>
                    </div>
                ))}
            </Menu>
        </div>
    );

    return (
        <>
            <Spinner isSpinner={isSpinner} />

            {name === "autosale" ? (
                <AutoSaleForm name={name} modal2Open={modal2Open} handleOkClick={handleOkClick} handleCancel={handleCancel} />
            ) : name === "ledgermaster" || name === "item" ? (
                <QuickcreateModel name={name} modal2Open={modal2Open} handleOkClick={handleOkClick} handleCancel={handleCancel} />
            ) : name === "autoexpenses" ? (
                <AutoExpensesForm name={name} modal2Open={modal2Open} handleOkClick={handleOkClick} handleCancel={handleCancel} />
            ) : name === "tc-sales" || name === "tc-purchase" ? (
                <TransectionAll
                    pathWithoutSlash={pathWithoutSlash}
                    handleChange={handleChange}
                    name={name}
                    modal2Open={modal2Open}
                    handleOkClick={handleOkClick}
                    handleCancel={handleCancel}
                />
            ) : (
                <BankingAll
                    pathWithoutSlash={pathWithoutSlash}
                    selectedBankName={selectedBankName}
                    setSelectedBankName={setSelectedBankName}
                    handleChange={handleChange}
                    selectedDataArrayBank={selectedDataArrayBank}
                    excelType={excelType}
                    setExcelType={setExcelType}
                    handleBankOptionSelect={handleBankOptionSelect}
                    name={name}
                    modal2Open={modal2Open}
                    handleOkClick={handleOkClick}
                    handleCancel={handleCancel}
                />
            )}

            <Dropdown overlay={menu} open={dropdownVisible} onOpenChange={(visible) => setDropdownVisible(visible)} trigger={["click"]}>
                <span onClick={handleButtonClick} className="quickcreate-mode header-icon-pd">
                    <PlusSquareFilled className="icon-header icon-header-blue" />
                </span>
            </Dropdown>
        </>
    );
};

export default withRouter(withProfile(withRole(Quickcreate)));
