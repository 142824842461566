// const processImageForText = async (imageFile) => {
//     try {
//         // const extractedText = await callGoogleApi(imageFile);
//         const extractedText = imageFile;
//         const jsonData = await textToJson(extractedText);
//         return jsonData; // Return the extracted JSON data
//     } catch (error) {
//         console.error("Error processing image:", error);
//         return null;
//     }
// };

// const callGoogleApi = async (image) => {
//     const googleApiKey = "AIzaSyBKfjD56j-H0Y7YRcFp99xYH3NEL83v4b4";

//     const imageToBase64 = (file) => {
//         return new Promise((resolve, reject) => {
//             const reader = new FileReader();
//             reader.readAsDataURL(file);
//             reader.onload = () => resolve(reader.result.split(",")[1]); // Extract base64 content
//             reader.onerror = (error) => reject(error);
//         });
//     };

//     try {
//         const base64Image = await imageToBase64(image);
//         const response = await fetch(`https://vision.googleapis.com/v1/images:annotate?key=${googleApiKey}`, {
//             method: "POST",
//             headers: {
//                 "Content-Type": "application/json",
//             },
//             body: JSON.stringify({
//                 requests: [
//                     {
//                         image: { content: base64Image },
//                         features: [{ type: "TEXT_DETECTION", maxResults: 1 }],
//                     },
//                 ],
//             }),
//         });

//         const data = await response.json();
//         console.log("data", data);

//         return data.responses?.[0]?.fullTextAnnotation?.text || null;
//     } catch (error) {
//         console.error("Error with Google OCR:", error);
//         return null;
//     }
// };

// let textData1 = `GSTIN: 24CPXPS3962P1ZN
// TAX INVOICE
// ARIHANT ELECTRONICS
// SATYAM TOWAR SHOP 8 GF 8
// VILL-TARSADI KOSAMBA
// TA MANGROL DIST SURAT
// KOSAMBA, Gujarat - 394120, India
// Phone No: 919173567123 | Email: ankit.jain.aj72@gmail.com
// Invoice No.
// Reverse Charge
// Place of Supply
// Payment Term
// A/01903/24-25
// : No
// : Gujarat
// : G PAY
// State Code: 24
// Invoice Date
// Bill Type
// : 10/09/2024
// Cash
// Station
// Due Date
// : 10/09/2024
// Original For Recipient
// Customer Name & Billing Address
// KAGZI INFOTECH
// SHOP NO-4/236, MATAN MARKET,MAIN BAZAR, KOSAMBA, TALUKA
// MANGROL,Gujarat 394120 Gujarat 394120 India
// GSTIN/UIN:
// State Code: 24
// 24CBXPK3120K1ZG
// Party Contact Person: IRFAN IBRAHIM KAGZI
// Shipping Address
// KAGZI INFOTECH
// SHOP NO-4/236, MATAN MARKET,MAIN BAZAR, KOSAMBA,
// TALUKA MANGROL,Gujarat 394120 Gujarat 394120 India
// State Code. 24
// S NO
// Description
// 1
// BLUE STAR WATER DISP
// BWD3FMRGA24HNE07506
// HSN / SAC
// 84186920
// Qty
// UOM
// 1.00 PCS
// Item Rate
// 9,500.00
// Disc %
// Tax
// 18%
// Amount (INR)
// 9,500.00
// Total
// 1.00
// 9,500.00
// Tax Rate
// TAX 18%
// Taxable Value
// 8,050.85
// CGST Amount SGST Amount IGST Amount Total Tax
// 724.58
// 724.58
// 0.00 1,449.16
// Sub Total
// 9,500.00
// Taxable Amount
// CGST
// 8,050.85
// 724.58
// SGST/UTGST
// Round Off
// 724.58
// Tax Amount : INR One Thousand Four Hundred Fourty Nine and Sixteen Paisa Only
// Bill Amount: INR Mine Thousand Five Hundred Only
// Bill Total
// 0.00
// 9,500.00
// Narration
// Document extra info
// Sales Custom Field
// Being Goods Sold to KAGZI INFOTECH
// Bank Details: STATE BANK OF INDIA | 32105555209 | KOSAMBA | SBIN0060358
// Declaration:
// We declare that this invoice shows the actual price of the goods / services
// described and that all particulars are true and correct.
// Terms and Conditions:
// Receiver's Signature
// For ARIHANT ELECTRver.
// Arihant
// Shop No&
// Haram Chowkdi,
// Taradi-Kosamba (R.S.).
// Authoris Dist. Surat-394120
// Signatory
// Page: 1/1`;

// let textData2 = `SHREE KRISHNA ELECTRICALS
// Original/Duplicate/Triplicate
// opp.manglam traders Kabirvan, Kharach Road,,Kuvarda, kosamba ta mangrol dist surat
// t-mo-9979938599,
// shreekrishna vishnu0@gmail.com
// M/s. KAGZI INFOTECH
// SHOP NO-4/236, MATAN MARKET.
// MAIN BAZAR, KOSAMBA,
// TALUKA MANGROL, Dist:Surat
// TAX Invoice
// Credit Memo
// EV
// Invoice No
// T0591
// Invoice Date
// : 25-07-2024
// Gujarat State Code: 24
// Co
// GSTIN No:24CBXPK3120K1ZG
// Ki
// HSN
// S
// Sr.
// Description
// Code
// Quantity
// 1 Rccb 32a Dp Lexur
// 8536
// 2 Taparia Screw Driver
// 8205
// 1 Nos
// 1 Nos
// Rate
// 805.08
// SGST CGST Amount
// 9% 9% 805.08
// 72.03 9% 9% 72.03
// Remarks:
// Basic
// 877.11
// GST%
// 18%
// SGST
// 78.94
// CGST
// IGST
// 78.94
// 0.00
// 2
// Total Bill Amount Rs.One Thousand Thirty-Five Only
// GSTIN: 24AZAPM1449E1ZR State: Gujarat State Code: 24
// BANK OF BARODA A/C 22060500000516 IFSC: BARBOKHARAC, Kharach Branch
// Terms And Condition :-
// (1) Interest will be charged at18% per annum if the bill is
// not paid onpresentation. (2) Goods once sold will not be
// taken back. (3) Our responsibility ceases as soon as goods
// leaves our premises. (4) Warranty as per respective company
// norms. (5) Subject to MANGROL Jurisdication.
// Receiver's Signature
// Total
// 877.11
// SGST 9%
// 78.95
// CGST 9%
// 78.95
// Round Off
// -0.01
// Net Amount
// 1035.00
// For, SHREE KRISHNA ELECTRICALS
// Checked By
// Software By Krishna, 98250 85992, 98254 25385
// Authorised Signature`;

// let textData3 = `111
// TAX INVOICE
// Invoice No.
// EVIAN
// WORLD.COM
// EVIAN WORLD LLP
// 845/294,Mahadev Campers, Simada Canal Road,
// B/h Khodaldham Party Plot, Opp Poddar School, Surat
// UDYAM: UDYAM-GJ-22-0242830 (Micro/Mfgr)
// GSTIN/UIN: 24AAKFE1368F1ZL
// State Name: Gujarat, Code: 24
// Contact: +91-9724042273
// 2150
// Delivery Note
// Dispatch Doc No.
// E-Mail: sales.evianworld@gmail.com
// Consignee (Ship to)
// Kagzi Infotech
// Shop No-4/236, Matan Market, Main Bazar, Kosamba,
// Taluka Mangrol, Surat
// GSTIN/UIN
// PAN/IT NO
// State Name
// Contact
// Buyer (Bill to)
// Kagzi Infotech
// : 24CBXPK3120K1ZG
// : CBXPK3120K
// : Gujarat, Code: 24
// : 9426778445
// Shop No-4/236, Matan Market, Main Bazar, Kosamba,
// Taluka Mangrol, Surat
// GSTIN/UIN
// PAN/IT NO
// State Name
// : 24CBXPK3120K1ZG
// : CBXPK3120K
// : Gujarat, Code: 24
// Contact
// SI
// No.
// 1 SMC-24
// Black Net
// : 9426778445
// Description of Goods
// Output CGST
// Output SGST
// Round Off
// Dated
// 16-Dec-24
// Mode/Terms of Payment
// Delivery Note Date
// Dispatched through
// Destination
// Kosamba
// Terms of Delivery
// HSN/SAC
// Quantity
// Rate
// (Incl. of Tax)
// Rate
// per
// Amount
// 9403
// 8.000 PC
// 2,800.00
// 2,372.88 PC
// 18,983.04
// Total
// 8.000 PC
// Amount Chargeable (in words)
// INR Twenty Two Thousand Four Hundred Only
// Scan to pay
// HSN/SAC
// 9403
// Total
// Taxable
// Value
// 18,983.04
// CGST
// SGST/UTGST
// Rate Amount
// 9% 1,708.47
// Rate Amount
// 9% 1,708.47
// 18,983.04
// 1,708.47
// 1,708.47
// 1,708.47
// 0.02
// 22,400.00
// E.&O.E
// Total
// Tax Amount
// 3,416.94
// 1,708.47
// 3,416.94
// Tax Amount (in words): INR Three Thousand Four Hundred Sixteen and Ninety Four paise Only
// Company's Bank Details
// Company's PAN
// Declaration
// : AAKFE1368F
// 1. Goods once sold will not be taken back.
// 2. Interest @18% p.a. will be charged if payment is not made
// within due date.
// 3. Our risk and responsibility ceases as soon as the goods
// leave our premises.
// 4. WARRENTY: IN OFFICE CHAIR 1 YEAR OF WHEEL,
// HYDRALIC & MECHANISM ONLY.
// 5. NO GUARANTEE OR WARRENTY IN ANY IMPORTED
// PRODUCTS OR OFFICE TABLE.
// Bank Name
// A/c No.
// : ICICI Bank Ltd
// : 724605500722
// Branch & IFS Code: YOGI CHOWK & ICIC0007246
// fol
// EVIAN
// LLP
// NORRED
// Yest..
// LLA
// *
// SUBJECT TO SURAT JURISDICTION
// This is a Computer Generated Invoice
// Authorised Signatory`;

// let textData4 = `STD
// E-Kart Logistics
// OD331988604569930100
// SURFACE
// COD
// Ordered through
// Flipkart
// (N) STV/KSM
// AWB No. FMPC3942961205
// HBD: 09-08
// CPD: 14-08
// Shipping/Customer address:
// Name: KAGZI INFOTECH,
// SHOP NO 4/236, MATAN MARKET. MAIN
// BAZAR KOSAMBA, KOSAMBA,
// BESIDE AMIN XEROX,
// KOSAMBA
// 394120, IN-GJ
// Sold By:LITTLE TECH CO, B-3, A-125, Sector 63 Noida, Gautam Buddha
// Nagar Uttar Pradesh India, Landmark: Little Tech Warehouse District: Nolda
// State: Uttar Pradesh, NOIDA - 201301
// GSTIN: 09DBOPA8309G1ZI
// SKU ID | Description
// 1TVS Champ Executive Multimedia Combo | Tvs Electronics
// Champ Executive Multimedia Combo (Wired
// QTY
// 1
// FMPC3942961205
// Not for resale.
// Printed at 0949 hrs, 08/08/24
// Tax
// Invoice
// Sold By
// LITTLE TECH CO.
// Order Id:
// OD331988604569930100
// Order Date: 07-08-2024, 05:08
// PM
// B-3, A-125, Sector 63 Nolda, : Gautam
// Buddha Nagar Uttar Pradesh India.
// Landmark: Little Tech Warehouse District
// : Nolda State : Uttar Pradesh, NOIDA -
// 201301
// GST: 09DBOPA8309G1ZI
// Product
// Invoice No:
// FAG3212500000822
// Invoice Date: 07-08-2024,
// 05:09 PM
// Shipping ADDRESS
// KAGZI INFOTECH,
// SHOP NO 4/236, MATAN MARK
// ET. MAIN BAZAR KOSAMBA,
// KOSAMBA,
// BESIDE AMIN XEROX,
// KOSAMBA - 394120, IN-GJ
// Description
// Tvs Electronics Champ Executive Multimedia
// Combo (Wired Keyboard & Mouse) 10 Million
// keystrokes Wired USB Desktop Keyboard Black HSN: 84716040 | IGST:
// 18.00%
// GSTIN:
// 09DBOPA8309G1ZI
// PAN: DBOPA8309G
// Billing Address
// KAGZI INFOTECH.
// SHOP NO 4/236, MATAN MARKET. MAIN
// BAZAR KOSAMBA,
// KOSAMBA,
// BESIDE AMIN XEROX.
// KOSAMBA 394120, IN-GJ
// Qty
// Gross
// Amount
// Discount
// Taxable
// Value
// IGST
// Total
// 1
// 799.00
// -0.00
// 677.12
// 121.88
// 799.00
// 0.00
// TOTAL PRICE: 799.00
// All values are in INR
// ITVS Champ Executive Multimedia Combo |
// IMEI/SrNo: [[]]
// Shipping and Handling
// Charges
// 1
// 0.00
// о
// 0.00
// 0.00
// TOTAL QTY: 1
// Seller Registered Address: LITTLE TECH CO.
// LITTLE TECH CO, Sector 26, Nolda - 201301.
// Declaration
// The goods sold are Intended for end user consumption and not for resale.
// E.&O.E.
// Ordered Through
// Flipkart
// LITTLE TECH CO
// Authorized Signature
// Apara`;

// // const textToJson = async (textData) => {
// //     function extractValue(regex, defaultValue) {
// //         const match = textData2.match(regex);
// //         return match ? match[1].trim() : defaultValue;
// //     }

// //     function parseInvoiceText(textData) {
// //         // console.log("Processing textData:", textData);

// //         const result = {
// //             voucherType: textData.match(/TAX INVOICE/i) ? "tax invoice" : "",
// //             voucherNo: extractValue(/Invoice No\s*([A-Z0-9\/-]+)/i, ""),
// //             voucherDate: extractValue(/Invoice Date\s*[:]?\s*(\d{2}-\d{2}-\d{4})/i, ""),
// //             partyName: extractValue(/M\/s\.\s*([A-Z0-9\s,\.]+)/i, "")
// //                 .split("\n")[0]
// //                 .trim(),
// //             gstNumber: extractValue(/GSTIN[:\s]+([0-9A-Z]+)/i, ""),
// //             salesLedger: "Sales A/c.",
// //             items: [],
// //             amount: extractValue(/Total\s*Bill\s*Amount.*?Rs\.([\d,]+\.?\d{0,2})/i, ""),
// //             ledgerName: "",
// //             ledgerAmount: "",
// //             taxLedgerData: {
// //                 CGST: extractValue(/CGST\s*9%\s*(\d+\.\d{2})/i, "0.00"),
// //                 SGST: extractValue(/SGST\s*9%\s*(\d+\.\d{2})/i, "0.00"),
// //             },
// //         };

// //         // Split the textData into lines
// //         const lines = textData.split("\n");

// //         // Temporary variables to store item details
// //         let currentItem = null;

// //         // Iterate through each line
// //         for (let i = 0; i < lines.length; i++) {
// //             const line = lines[i].trim();

// //             const itemMatch = line.match(/^(\d+)\s+([\w\s\-]+)\s+(\d{4})$/);
// //             if (itemMatch) {
// //                 // If a new item is found, push the previous item to the result
// //                 if (currentItem) {
// //                     result.items.push(currentItem);
// //                 }

// //                 // Create a new item object
// //                 currentItem = {
// //                     index: result.items.length,
// //                     itemName: itemMatch[2].trim(),
// //                     hsnCode: itemMatch[3].trim(),
// //                     qty: "",
// //                     rate: "",
// //                 };
// //             }
// //         }

// //         return result;
// //     }

// //     const result = parseInvoiceText(textData2);
// //     // console.log("Parsed Result:", result);
// //     return result;
// // };

// //for all --------------------------------------------------------------------
// const textToJson = async (textData) => {
//     function parseInvoiceText(textData) {
//         function extractValue(regex, defaultValue = "") {
//             const match = textData.match(regex);
//             return match ? match[1]?.trim() : defaultValue;
//         }
//         const result = {
//             voucherType: extractValue(/(?:TAX INVOICE|Sales \[Bill of Supply\])/i, ""),
//             voucherNo: extractValue(/(?:Invoice No|Supplier Invoice No)[:\s]*([A-Z0-9\/-]+)/i, ""),
//             voucherDate: extractValue(/(?:Invoice Date|Dated)[:\s]*(\d{1,2}[-/][A-Za-z0-9]+[-/]\d{2,4})/i, ""),
//             partyName: extractValue(/(?:M\/s\.|Buyer \(Bill to\)|Billing Address)\s*([A-Z0-9\s,\.]+)/i, "")
//                 .split("\n")[0]
//                 .trim(),
//             gstNumber: extractValue(/(?:GSTIN|GSTIN\/UIN)[:\s]+([0-9A-Z]+)/i, ""),
//             salesLedger: "Sales A/c.",
//             totalAmount: extractValue(
//                 /TOTAL PRICE:\s*([\d.]+)/,
//                 extractValue(
//                     /Amount \(INR\)\s*\n\s*([\d,]+\.?\d*)/i,
//                     extractValue(/Amount Chargeable \(in words\)\n.*\n.*\s([\d.,]+)/, "0.00")
//                 ).replace(/,/g, "")
//             ),
//             ledgerName: "",
//             ledgerAmount: "",
//             taxLedgerData: {
//                 CGST: extractValue(/CGST[:\s]*9%\s*(\d+\.\d{2})/i, "0.00"),
//                 SGST: extractValue(/SGST[:\s]*9%\s*(\d+\.\d{2})/i, "0.00"),
//                 IGST: extractValue(/IGST[:\s]*(\d+\.\d{2})/i, "0.00"),
//             },
//             items: [],
//         };

//         const itemRegex =
//             /(?:Description|Item)\s*\n\s*(\d+)\s*\n\s*([^\d\n]+).*?Qty\s*\n\s*UOM\s*\n\s*([\d\.]+).*?Item Rate\s*\n\s*([\d,]+\.?\d*)/gis;
//         const matches = [...textData.matchAll(itemRegex)];

//         matches.forEach((match, index) => {
//             result.items.push({
//                 index: index,
//                 itemName: match[2].trim(),
//                 qty: match[3].split(".")[0],
//                 rate: match[4].replace(/,/g, ""),
//             });
//         });

//         return result;
//     }

//     // const result = parseInvoiceText(textData1);
//     console.log("Parsed Result:", parseInvoiceText(textData1));
//     console.log("Parsed Result:", parseInvoiceText(textData2));
//     console.log("Parsed Result:", parseInvoiceText(textData3));
//     console.log("Parsed Result:", parseInvoiceText(textData4));
// };

// //---------------------------------------------------------------------------------
// // const textToJson = async (textData) => {
// //     function parseInvoiceText(textData) {
// //         const result = {
// //             voucherType: textData.match(/TAX INVOICE/i) ? "tax invoice" : "",
// //             voucherNo: textData.match(/Invoice No\.?\s*([A-Z0-9\/\-]+)/i)?.[1] || "",
// //             voucherDate: textData.match(/\b(\d{2}[-/]\d{2}[-/]\d{4})\b/g)?.[0] || "",
// //             partyName:
// //                 textData
// //                     .match(/Customer Name & Billing Address\s*([A-Z0-9\s,]+)/i)?.[1]
// //                     ?.split("\n")[0]
// //                     .trim() || "",
// //             gstNumber: textData.match(/GSTIN[:\s]+([0-9A-Z]+)/i)?.[1] || "",
// //             salesLedger: "Sales A/c.",
// //             items: [], // Array to hold multiple items
// //             totalAmount: textData.match(/Amount \(INR\)\s*\n\s*([\d,]+\.?\d*)/i)?.[1]?.replace(/,/g, "") || "",
// //             ledgerName: "",
// //             ledgerAmount: "",
// //             taxLedgerData: {
// //                 CGST: textData.match(/CGST Amount\s*\n\s*([\d,]+\.?\d*)/i)?.[1]?.replace(/,/g, "") || "0.00",
// //                 SGST: textData.match(/SGST Amount\s*\n\s*([\d,]+\.?\d*)/i)?.[1]?.replace(/,/g, "") || "0.00",
// //             },
// //         };

// //         // Match all items with their quantities and rates
// //         const itemRegex = /Description\s*\n\s*\d+\s*\n\s*([^\d\n]+).*?Qty\s*\n\s*UOM\s*\n\s*([\d\.]+).*?Item Rate\s*\n\s*([\d,]+\.?\d*)/gis;
// //         const matches = [...textData.matchAll(itemRegex)];

// //         matches.forEach((match) => {
// //             result.items.push({
// //                 itemName: match[1].trim(),
// //                 qty: match[2].split(".")[0], // Extract whole number
// //                 rate: match[3].replace(/,/g, ""), // Remove commas
// //             });
// //         });

// //         return result;
// //     }

// //     let result = parseInvoiceText(textData1);
// //     console.log("result", result);
// //     return result;
// // };
// //---------------------------------------------------------------------------------
// // const textToJson = async (textData) => {
// //     // console.log("textData", textData);

// //     function parseInvoiceText(textData) {
// //         const extractValue = (regex, defaultValue = "") => {
// //             const match = textData.match(regex);
// //             return match ? match[1].trim() : defaultValue;
// //         };

// //         const invoiceData = {
// //             totalAmount: extractValue(/TOTAL PRICE:\s*([\d.]+)/, "0.00"),
// //             basicTotal: extractValue(/Taxable\s*Value\s*(\d+\.\d+)/, "0.00"),
// //             billTo: extractValue(/Billing Address\s*([\s\S]+?)Qty/, "").replace(/\n/g, " "),
// //             consigneeAddress: extractValue(/Shipping\/Customer address:\s*([\s\S]+?)Sold By:/, "").replace(/\n/g, " "),
// //             consigneeGstNum: extractValue(/GSTIN:\s*([\w\d]+)/, ""),
// //             consigneePincode: extractValue(/KOSAMBA\s*-\s*(\d+)/, ""),
// //             consigneeState: "Gujarat",
// //             date: extractValue(/Invoice Date:\s*([\d-]+)/, "07-08-2024"),
// //             gstNum: extractValue(/GST:\s*([\w\d]+)/, ""),
// //             items: [
// //                 {
// //                     name: extractValue(/Description\s*([\s\S]+?)HSN/, "").trim(),
// //                     quantity: extractValue(/TOTAL QTY:\s*(\d+)/, "1"),
// //                     rate: extractValue(/Taxable\s*Value\s*(\d+\.\d+)/, "0.00"),
// //                     amount: extractValue(/TOTAL PRICE:\s*([\d.]+)/, "0.00"),
// //                 },
// //             ],
// //             ledgerTotal: "11.00",
// //             ledgers: [
// //                 {
// //                     ledgerName: "0 Cash",
// //                     amount: "11.00",
// //                 },
// //             ],
// //             partyAcname: extractValue(/Billing Address\s*([\s\S]+?)Qty/, "").replace(/\n/g, " "),
// //             partyPincode: extractValue(/KOSAMBA\s*-\s*(\d+)/, ""),
// //             partyState: "Gujarat",
// //             poc: "Gujarat",
// //             salesLedger: "Sales A/c.",
// //             shipTo: extractValue(/Shipping\/Customer address:\s*([\s\S]+?)Sold By:/, "").replace(/\n/g, " "),
// //             supplierInvoiceNo: extractValue(/Invoice No:\s*([\w\d]+)/, ""),
// //             taxLedgers: [
// //                 {
// //                     ledgerName: "IGST",
// //                     description: "",
// //                     amount: extractValue(/IGST:\s*(\d+\.\d+)/, "0.00"),
// //                 },
// //             ],
// //             total: extractValue(/TOTAL PRICE:\s*([\d.]+)/, "0.00"),
// //             voucherDate: extractValue(/Invoice Date:\s*([\d-]+)/, "07-08-2024"),
// //             voucherType: "Sales [Bill of Supply]",
// //         };

// //         return [invoiceData];
// //     }
// //     let result = parseInvoiceText(textData1);
// //     console.log("result", result);

// //     return result;
// // };

// //------------------------------------------------------------------------
// // const textToJson = async (textData) => {
// //     console.log("textData", textData);

// //     function parseInvoiceText(textData) {
// //         const extractValue = (regex, defaultValue = "") => {
// //             const match = textData.match(regex);
// //             return match ? match[1].trim() : defaultValue;
// //         };

// //         const invoiceData = {
// //             totalAmount: extractValue(/Amount Chargeable \(in words\)\n.*\n.*\s([\d.,]+)/, "0.00"),
// //             basicTotal: extractValue(/Total\s*Taxable\s*Value\s*([\d.,]+)/, "0.00"),
// //             billTo: extractValue(/Buyer \(Bill to\)\n(.*)/, "").split("\n")[0],
// //             consigneeAddress: extractValue(/Consignee \(Ship to\)\n(.*)/, "").split("\n")[0],
// //             consigneeGstNum: extractValue(/GSTIN\/UIN:\s*([\w\d]+)/, ""),
// //             consigneePincode: extractValue(/KOSAMBA\s*-\s*(\d+)/, ""),
// //             consigneeState: "Gujarat",
// //             date: extractValue(/Dated\s*(\d{1,2}-[A-Za-z]+-\d{2})/, ""),
// //             gstNum: extractValue(/GSTIN\/UIN:\s*([\w\d]+)/, ""),
// //             items: [
// //                 {
// //                     name: extractValue(/Description of Goods\n(.*)/, ""),
// //                     quantity: extractValue(/Quantity\n([\d.]+)/, ""),
// //                     rate: extractValue(/Rate \(Incl. of Tax\)\n([\d.,]+)/, ""),
// //                     amount: extractValue(/Total\n([\d.,]+)/, ""),
// //                 },
// //             ],
// //             ledgerTotal: "11.00",
// //             ledgers: [
// //                 {
// //                     ledgerName: "0 Cash",
// //                     amount: "11.00",
// //                 },
// //             ],
// //             partyAcname: extractValue(/Buyer \(Bill to\)\n(.*)/, "").split("\n")[0],
// //             partyPincode: extractValue(/KOSAMBA\s*-\s*(\d+)/, ""),
// //             partyState: "Gujarat",
// //             poc: "Gujarat",
// //             salesLedger: "Sales A/c.",
// //             shipTo: extractValue(/Consignee \(Ship to\)\n(.*)/, "").split("\n")[0],
// //             supplierInvoiceNo: extractValue(/Invoice No\.\s*([\w\d]+)/, ""),
// //             taxLedgers: [
// //                 {
// //                     ledgerName: "IGST",
// //                     description: "",
// //                     amount: extractValue(/IGST:\s*(\d+\.\d+)/, "0.00"),
// //                 },
// //             ],
// //             total: extractValue(/Amount Chargeable \(in words\)\n.*\n.*\s([\d.,]+)/, "0.00"),
// //             voucherDate: extractValue(/Dated\s*(\d{1,2}-[A-Za-z]+-\d{2})/, ""),
// //             voucherType: "Sales [Bill of Supply]",
// //         };

// //         return invoiceData;
// //     }

// //     let result = parseInvoiceText(textData3);
// //     return result;
// // };

// export default processImageForText;

import axios from "axios";

const processImageForText = async (imageFile) => {
    try {
        const extractedText = await callGoogleApi(imageFile);
        return extractedText;
    } catch (error) {
        console.error("Error processing image:", error);
        return null;
    }
};

const callGoogleApi = async (image) => {
    try {
        const form = new FormData();
        form.append("file", image);
        form.append("type", "invoice");

        const options = {
            method: "POST",
            headers: {
                apikey: "jZJpOrRhKtXEl8elkOm5yEcIlYGoikzq8vOHcy1ayPXOdTaQYIo63I1yjJCQ",
            },
        };

        options.body = form;

        fetch("https://app.docsumo.com/api/v1/eevee/apikey/upload/", options)
            .then((res) => res.json())
            .then((res) => console.log(res))
            .catch((err) => console.error(err));

        // let formData = new FormData();
        // formData.append("file", image);
        // formData.append("type", "invoice");

        // const response = await axios.post("https://api.docsumo.com/v1/upload", formData, {
        //     headers: {
        //         "api-key": "jZJpOrRhKtXEl8elkOm5yEcIlYGoikzq8vOHcy1ayPXOdTaQYIo63I1yjJCQ",
        //         // "Content-Type": "multipart/form-data",
        //     },
        // });

        // console.log("response", response);
    } catch (error) {
        console.error("Error with Google OCR:", error);
        return null;
    }
};

export default processImageForText;
