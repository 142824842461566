import React, { useState } from "react";
import { Modal } from "antd";
import { FileUploader } from "react-drag-drop-files";
import { withProfile } from "../../../../contexts/profileContext";
import { PaperClipOutlined } from "@ant-design/icons";

const TransectionAll = ({ handleChange, name, modal2Open, handleOkClick, handleCancel }) => {
    const imagefileTypes = ["xlsx", "xls", "jpeg"];
    const [fileTitle, setFileTitle] = useState("");

    const handleFileChange = (file) => {
        handleChange(file);
        setFileTitle(file[0].name);
    };

    return (
        <>
            <Modal
                title={`Upload ${name}`}
                centered
                open={modal2Open}
                onOk={handleOkClick}
                onCancel={handleCancel}
                width={700}
                okText="Upload"
                className="bulk_upload_model"
            >
                <div className="upl-page-body">
                    <form action="">
                        <div className="upload-file">
                            <FileUploader
                                multiple={true}
                                handleChange={handleFileChange}
                                name="file"
                                types={imagefileTypes}
                                className="upload-file-btn"
                            />
                        </div>
                        <div className="note">
                            <div className="note-ledger">
                                {fileTitle && (
                                    <p className="fileTitle_text">
                                        <PaperClipOutlined style={{ fontSize: "20px", color: "#1890ff", margin: "0 8px" }} />
                                        {fileTitle}
                                    </p>
                                )}
                                <p>Notes:</p>
                                <ul>
                                    <li>Please make sure you can upload 1 invoices at a time.</li>
                                    <li>Please make sure the file size must not exceed 50MB.</li>
                                </ul>
                            </div>
                        </div>
                    </form>
                </div>
            </Modal>
        </>
    );
};

export default withProfile(TransectionAll);
