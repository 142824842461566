import React, { useState } from "react";
import { withProfile } from "../../../contexts/profileContext";
import logoDark from "../../../assets/images/45x45.png";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import bcrypt from "bcryptjs";

import { GoogleLogin } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";
import Spinner from "../../Common/Spinner/Spinner";
import { Input } from "antd";

const Login = ({ validatedProfile, setValidatedProfile }) => {
    const [username, setUsername] = useState();
    const [password, setPassword] = useState();
    const [isSpinner, setIsSpinner] = useState(false);
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSpinner(true);
        try {
            const hashedPassword = await hashWithFixedSalt(password);

            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/signin`,

                {
                    identifier: username,
                    password: hashedPassword,
                    session_type: "WEB",
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            );

            if (response.data.google_user === true) {
                setIsSpinner(false);
                alert("You are registered with Google ID. Please log in using Google.");
            } else if (response.data.userRegister === false) {
                setIsSpinner(false);
                alert("You are not registered. Please register.");
            } else if (response.data.sessionActive === true) {
                setIsSpinner(false);
                let userConfirmed = window.confirm(
                    "You are already logged in on another device.\n" +
                        "You can only be logged in on one device at a time.\n" +
                        "Do you want to sign out from other devices?"
                );

                if (userConfirmed) {
                    try {
                        const token = response.data.token;

                        const responseSignOut = await axios.post(
                            `${process.env.REACT_APP_API_URL}/signout`,
                            { identifier: username, session_type: "WEB" },
                            {
                                headers: {
                                    Authorization: `Bearer ${token}`,
                                },
                            }
                        );
                        if (responseSignOut) {
                            const rehashedPassword = await hashWithFixedSalt(password);

                            const responseReSignIn = await axios.post(
                                `${process.env.REACT_APP_API_URL}/signin`,

                                {
                                    identifier: username,
                                    password: rehashedPassword,
                                    session_type: "WEB",
                                }
                            );

                            setValidatedProfile(responseReSignIn.data);
                            setIsSpinner(false);
                            navigate("/");
                        }
                    } catch (error) {
                        console.log("error", error);
                        alert(error?.response.data.message);
                    }
                }
            } else {
                setValidatedProfile(response.data);

                setIsSpinner(false);
                navigate("/");
            }
        } catch (error) {
            console.log("error", error);
            setIsSpinner(false);
            // alert(error?.response?.data?.message);
        }
        async function hashWithFixedSalt(password) {
            const fixedSalt = "$2b$10$yourfixedsaltforyourapplication";
            const hashedPassword = await bcrypt.hash(password, fixedSalt);

            return hashedPassword;
        }
    };

    const handleGoogleLoginSuccess = async (googleUser) => {
        setIsSpinner(true);
        const credentialResponseDecoded = jwtDecode(googleUser.credential);
        const googleId = credentialResponseDecoded.sub;
        const email = credentialResponseDecoded.email;
        const name = credentialResponseDecoded.name;

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/register`, {
                email: email,
                name: name,
                googleId: googleId,
                session_type: "WEB",
            });

            if (response.data.identifier_user === true) {
                setIsSpinner(false);
                alert("You are registered with Email/Mobile and password. Please log in using Email/Mobile.");
            } else if (response.data.sessionActive === true) {
                setIsSpinner(false);
                var userConfirmed = window.confirm(
                    "You are already logged in on another PC or laptop. You can only log in on one device at a time. Do you want to sign out from other devices?"
                );
                if (userConfirmed) {
                    try {
                        const token = response.data.token;

                        const responseSignOut = await axios.post(
                            `${process.env.REACT_APP_API_URL}/signout`,
                            { identifier: googleId, session_type: "WEB" },
                            {
                                headers: {
                                    Authorization: `Bearer ${token}`,
                                },
                            }
                        );
                        if (responseSignOut) {
                            const responseReRegister = await axios.post(`${process.env.REACT_APP_API_URL}/register`, {
                                email: email,
                                name: name,
                                googleId: googleId,
                                session_type: "WEB",
                            });

                            setValidatedProfile(responseReRegister.data);
                            setIsSpinner(false);
                            navigate("/");
                        }
                    } catch (error) {
                        console.log("error", error);
                        alert(error.response.data.message);
                    }
                }
            } else {
                setValidatedProfile(response.data);
                setIsSpinner(false);
                navigate("/");
            }
        } catch (error) {
            console.log("error", error);
            setIsSpinner(false);
            alert(error.response.data.message);
        }
    };

    const responseGoogleFailure = (response) => {
        console.log("Google Login Failure:", response);
    };

    return (
        <>
            <Spinner isSpinner={isSpinner} />
            <div className="bg-overlay"></div>
            <div className="auth-bg">
                <div className="auth-page">
                    <div>
                        <div className="titel">
                            <img src={logoDark} alt="logo" />
                            <p className="titel_text">Tax-link</p>
                        </div>
                        <h4 className="text-muted welcome_text">Welcome</h4>
                    </div>
                    <div className="GoogleLogin_div">
                        <GoogleLogin
                            clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                            buttonText="Login with Google"
                            onSuccess={handleGoogleLoginSuccess}
                            onFailure={responseGoogleFailure}
                            cookiePolicy={"single_host_origin"}
                            apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                        />
                    </div>
                    <div className="separator">
                        <span>or</span>
                    </div>
                    <div className="text-left">
                        <form onSubmit={handleSubmit}>
                            <div className="login_page_input_div">
                                <label className="form-label" htmlFor="Mobile/Email">
                                    Mobile / Email<b className="text-danger">*</b>
                                </label>
                                <Input
                                    type="text"
                                    id="Mobile/Email"
                                    className="form-control"
                                    placeholder="Enter Mobile/Email"
                                    required
                                    onChange={(e) => setUsername(e.target.value)}
                                />
                            </div>
                            <div className="login_page_input_div">
                                <label className="form-label" htmlFor="userpassword">
                                    Password<b className="text-danger">*</b>
                                </label>

                                <Input.Password
                                    type="password"
                                    id="userpassword"
                                    placeholder="Enter Password"
                                    onChange={(e) => setPassword(e.target.value)}
                                    visibilityToggle={true}
                                />
                            </div>
                            <div className=" forgot_div">
                                <Link className="text-primary" to="/signIn-with-otp">
                                    Sign in with OTP
                                </Link>
                                <Link className="text-primary" to="/forgot-password">
                                    Forgot your password?
                                </Link>
                            </div>
                            <div>
                                <button className="btn btn-primary log_in_btn" type="submit">
                                    Log In
                                </button>
                            </div>
                        </form>
                    </div>

                    <div className="text-center">
                        <span>
                            Don't have an account?{" "}
                            <Link className="text-primary" to="/register">
                                Register
                            </Link>
                        </span>
                    </div>
                </div>
            </div>
        </>
    );
};

export default withProfile(Login);
