import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Button, Modal } from "antd";
import { DeleteOutlined, PlusCircleOutlined, DownOutlined } from "@ant-design/icons";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-balham.css";
import "ag-grid-enterprise";

import { withProfile } from "../../../contexts/profileContext";

import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import isBetween from "dayjs/plugin/isBetween";
import { useCallback } from "react";
import { AgGridReact } from "ag-grid-react";

const { confirm } = Modal;

dayjs.extend(customParseFormat);
dayjs.extend(isBetween); // Extend dayjs with isBetween plugin

const typelist = ["Payment", "Receipt", "Contra Deposit", "Contra Withdraw"];

const AgGridSubTable = ({
    role,
    oldPathWithoutSlash,
    validatedProfile,
    fetchSubTableData,
    RowKey,
    path,
    setUpadateLedger,
    viewAll,
    ledgeroption,
    purchasevoucherType,
    purchaseLedgerUnderPurchaseAccounts,
    ledgerUnderSalesAccounts,
    stockitemNameOptions,
    partyAcNameOptions,
    rowData,
    selectedRowKeys,
    setSelectedRowKeys,
    startDate,
    endDate,
    setLedgerNameError,
    setStockitemNameError,
    selectedSlpitIdData,
    handleOpenSlpitMenu,
    ledgerNameError,
    stockitemNameError,
}) => {
    const [colDefs, setcolDefs] = useState([]);

    const location = useLocation();
    let { key, pathWithoutSlash, pathname, pathWithonlyname } = location.state || {};
    const pathnamecapital = pathWithoutSlash.charAt(0).toUpperCase() + pathWithoutSlash.slice(1);

    let pathPlusData;
    switch (pathWithoutSlash) {
        case "sales/excel":
            pathPlusData = "salesdata";
            break;
        case "item/excel":
            pathPlusData = "itemdata";
            break;
        case "ledgerbulk":
            pathPlusData = "ledgerbulkdata";
            break;
        default:
            pathPlusData = pathWithoutSlash + "data";
            break;
    }

    useEffect(() => {
        if (pathWithoutSlash === "sales/excel") {
            let stockitemNameErrorStatus = rowData.filter(
                (item) => stockitemNameOptions && !stockitemNameOptions.includes(item["name of item"])
            );
            let ledgerNameErrorStatus = rowData.filter((item) => ledgeroption && !ledgeroption.includes(item["party ac name"]));
            setStockitemNameError(stockitemNameErrorStatus);
            setLedgerNameError(ledgerNameErrorStatus);
        }
    }, [rowData]);

    useEffect(() => {
        setcolDefs(generateColumns(rowData));
    }, [rowData, ledgerNameError, stockitemNameError]);

    useEffect(() => {
        if (stockitemNameOptions || ledgeroption) {
            setcolDefs(generateColumns(rowData));
        }
    }, [ledgeroption, stockitemNameOptions]);

    // ================table  start ==============

    const getRowStyle = (params) => {
        if (params.data.flag === "SY") {
            return { background: "#E9ECEF" };
        }
    };

    const isCellEditable = useCallback((params) => {
        return params.data.flag !== "SY"; // disable editing for cells in rows with flag "SY"
    }, []);

    const gridOptions = {
        rowSelection: "multiple",
        isCellEditable,

        defaultColDef: {
            filterList: true,
            suppressMenu: true,
            sortable: true,
        },

        onSelectionChanged: (event) => {
            let selectedNodes = event.api.getSelectedNodes();
            const selectedIndexes = selectedNodes.map((node) => ({
                id: node.data.id,
            }));
            console.log("selectedIndexes", selectedIndexes);

            setSelectedRowKeys(selectedIndexes);
        },
    };

    const generateColumns = (input) => {
        // console.log("input", input[0]);
        if (!input || !input.length) return [];

        let fileType = input[0].fileitemtype === "Multiple";

        const columnConfig = {
            "name of item": {
                headerName: "Name of Item",

                headerComponentParams: {
                    template: `
         
                        <span class="ag-header-cell-label">
                            <span class="ag-header-cell-text">Name of Item</span>
                            <span 
                            class="custom-icon" 
                            onclick="handleIconClick('name_of_item')" 
                            style="margin-left: 8px; cursor: pointer;"
                            >
                            
                            ${
                                stockitemNameError?.length > 0
                                    ? `
                                <svg 
                                xmlns="http://www.w3.org/2000/svg" 
                                viewBox="0 0 24 24" 
                                fill="#FFA500" 
                                width="16" 
                                height="16" 
                                aria-hidden="true"
                                >
                                <path 
                                    d="M12 2c-.41 0-.79.25-.93.63L1.24 20.36A1.01 1.01 0 0 0 2 22h20c.71 0 1.18-.79.69-1.38L12.93 2.63A1 1 0 0 0 12 2zm0 15a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3zm1-10h-2v6h2V7z"
                                />
                                </svg>
                            `
                                    : ""
                            }
                            </span>
                    </span>
                    `,
                },
                field: "name of item",
                filter: "agTextColumnFilter",
                floatingFilter: true,

                editable: (params) => params.data.flag !== "SY",
                cellEditor: "agRichSelectCellEditor",
                cellEditorPopup: false,
                cellRenderer: (params) => {
                    const isError = stockitemNameOptions && !stockitemNameOptions.includes(params.value);
                    return (
                        <div className={`d-flex justify-content-between ${isError ? "text-danger" : "text-primary"}`}>
                            <span>{params.value || "Select Item"}</span>
                            <span>
                                <DownOutlined />
                            </span>
                        </div>
                    );
                },
                cellEditorParams: {
                    values: stockitemNameOptions,
                    searchType: "match",
                    allowTyping: true,
                    filterList: true,
                    highlightMatch: true,
                },
                onCellValueChanged: (params) => {
                    const selectedValue = params.data["name of item"];
                    setUpadateLedger((updateLedger) => [
                        ...updateLedger,
                        {
                            id: params.data.id,
                            name_of_item: selectedValue,
                            srno: params.data.srno,
                        },
                    ]);
                    const nextRowIndex = params.node.rowIndex + 1;
                    const rowCount = params.api.getDisplayedRowCount();

                    if (nextRowIndex < rowCount) {
                        params.api.setFocusedCell(nextRowIndex, "name of item");
                    }
                },
            },
            "party ac name": {
                headerName: "Party Ac Name",

                headerComponentParams: {
                    template: `
          <span class="ag-header-cell-label">
            <span class="ag-header-cell-text">Party Ac Name</span>
            <span 
              class="custom-icon" 
              onclick="handleIconClick('party_ac_name')" 
              style="margin-left: 8px; cursor: pointer;"
            >
             
              ${
                  ledgerNameError?.length > 0
                      ? `
                <svg 
                  xmlns="http://www.w3.org/2000/svg" 
                  viewBox="0 0 24 24" 
                  fill="#FFA500" 
                  width="16" 
                  height="16" 
                  aria-hidden="true"
                >
                  <path 
                    d="M12 2c-.41 0-.79.25-.93.63L1.24 20.36A1.01 1.01 0 0 0 2 22h20c.71 0 1.18-.79.69-1.38L12.93 2.63A1 1 0 0 0 12 2zm0 15a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3zm1-10h-2v6h2V7z"
                  />
                </svg>
              `
                      : ""
              }
            </span>
          </span>
         
        `,
                },
                field: "party ac name",
                filter: "agTextColumnFilter",
                floatingFilter: true,
                disabled: true,
                editable: (params) => {
                    const disableRowid = [];
                    const allRowData = [];
                    params.api.forEachNode((node) => {
                        let data = node.data;
                        if (allRowData.includes(data["reference no"] || data["supplier inv no"])) {
                            disableRowid.push(data.id);
                        } else {
                            allRowData.push(data["reference no"] || data["supplier inv no"]);
                        }
                    });
                    return (params.data.flag !== "SY" && !disableRowid.includes(params.data.id)) || !fileType;
                },

                cellClass: (params) => {
                    const isEditable = params.column.getColDef().editable(params);
                    return !isEditable ? "disable-css-cell-row" : "";
                },

                cellEditor: "agRichSelectCellEditor",
                cellEditorPopup: false,
                cellRenderer: (params) => {
                    const isError = ledgeroption && !ledgeroption.includes(params.value);
                    return (
                        <div className={`d-flex justify-content-between ${isError ? "text-danger" : "text-primary"}`}>
                            <span>{params.value || "Select Party"}</span>
                            <span>
                                <DownOutlined />
                            </span>
                        </div>
                    );
                },
                cellEditorParams: {
                    values: ledgeroption,
                    searchType: "match",
                    allowTyping: true,
                    filterList: true,
                    highlightMatch: true,
                },
                onCellValueChanged: (params) => {
                    const selectedValue = params.data["party ac name"];
                    setUpadateLedger((updateLedger) => [
                        ...updateLedger,
                        {
                            id: params.data.id,
                            party_ac_name: selectedValue,
                            srno: params.data.srno,
                        },
                    ]);
                    const nextRowIndex = params.node.rowIndex + 1;
                    const rowCount = params.api.getDisplayedRowCount();

                    if (nextRowIndex < rowCount) {
                        params.api.setFocusedCell(nextRowIndex, "party ac name");
                    }
                },
            },
            "purchase ledger": {
                headerName: "Purchase ledger",
                field: "purchase ledger",
                filter: "agTextColumnFilter",
                floatingFilter: true,

                editable: (params) => params.data.flag !== "SY",
                cellEditor: "agRichSelectCellEditor",
                cellEditorPopup: false,
                cellRenderer: (params) => {
                    const isError = ledgeroption && !ledgeroption.includes(params.value);
                    return (
                        <div className={`d-flex justify-content-between ${isError ? "text-danger" : "text-primary"}`}>
                            <span>{params.value || "Select Sales Ledger"}</span>
                            <span>
                                <DownOutlined />
                            </span>
                        </div>
                    );
                },

                cellEditorParams: {
                    values: ledgeroption,
                    searchType: "match",
                    allowTyping: true,
                    filterList: true,
                    highlightMatch: true,
                },

                onCellValueChanged: (params) => {
                    const selectedValue = params.data["purchase ledger"];
                    setUpadateLedger((updateLedger) => [
                        ...updateLedger,
                        {
                            id: params.data.id,
                            purchase_ledger: selectedValue,
                            srno: params.data.srno,
                        },
                    ]);
                    const nextRowIndex = params.node.rowIndex + 1;
                    const rowCount = params.api.getDisplayedRowCount();

                    if (nextRowIndex < rowCount) {
                        params.api.setFocusedCell(nextRowIndex, "purchase ledger");
                    }
                },
            },
            ledger: {
                headerName: "Ledger",
                field: "ledger",
                filter: "agTextColumnFilter",
                floatingFilter: true,

                editable: (params) => params.data.flag !== "SY",
                cellEditor: "agRichSelectCellEditor",
                cellEditorPopup: false,
                cellRenderer: (params) => (
                    <div className={`d-flex justify-content-between ${params.value ? "text-primary" : "text-secondary"}`}>
                        <span>{params.value || "Select Ledger"}</span>
                        <span>
                            <DownOutlined />
                        </span>
                    </div>
                ),
                cellEditorParams: {
                    values: ledgeroption,
                    searchType: "match",
                    allowTyping: true,
                    filterList: true,
                    highlightMatch: true,
                },

                onCellValueChanged: (params) => {
                    const selectedValue = params.data.ledger;
                    setUpadateLedger((updateLedger) => [
                        ...updateLedger,
                        {
                            id: params.data.id,
                            ledger: selectedValue,
                            srno: params.data.srno,
                        },
                    ]);
                    const nextRowIndex = params.node.rowIndex + 1;
                    const rowCount = params.api.getDisplayedRowCount();

                    if (nextRowIndex < rowCount) {
                        params.api.setFocusedCell(nextRowIndex, "ledger");
                    }
                },
            },
            "sales ledger": {
                headerName: "Sales Ledger",
                field: "sales ledger",
                filter: "agTextColumnFilter",
                floatingFilter: true,

                editable: (params) => params.data.flag !== "SY",
                cellEditor: "agRichSelectCellEditor",
                cellEditorPopup: false,

                cellRenderer: (params) => {
                    const isError = ledgerUnderSalesAccounts && !ledgerUnderSalesAccounts.includes(params.value);
                    return (
                        <div className={`d-flex justify-content-between ${isError ? "text-danger" : "text-primary"}`}>
                            <span>{params.value || "Select Sales Ledger"}</span>
                            <span>
                                <DownOutlined />
                            </span>
                        </div>
                    );
                },
                cellEditorParams: {
                    values: ledgerUnderSalesAccounts,
                    searchType: "match",
                    allowTyping: true,
                    filterList: true,
                    highlightMatch: true,
                },

                onCellValueChanged: (params) => {
                    const selectedValue = params.data["sales ledger"];
                    setUpadateLedger((updateLedger) => [
                        ...updateLedger,
                        {
                            id: params.data.id,
                            sales_ledger: selectedValue,
                            srno: params.data.srno,
                        },
                    ]);
                    const nextRowIndex = params.node.rowIndex + 1;
                    const rowCount = params.api.getDisplayedRowCount();

                    if (nextRowIndex < rowCount) {
                        params.api.setFocusedCell(nextRowIndex, "sales ledger");
                    }
                },
            },
            "purchase type": {
                headerName: "Voucher Type",
                field: "purchase type",
                filter: "agTextColumnFilter",
                floatingFilter: true,

                editable: (params) => params.data.flag !== "SY",
                cellEditor: "agRichSelectCellEditor",

                cellRenderer: (params) => (
                    <div className={`d-flex justify-content-between ${params.value ? "text-primary" : "text-secondary"}`}>
                        <span>{params.value || "Select Type"}</span>
                        <span>
                            <DownOutlined />
                        </span>
                    </div>
                ),
                cellEditorParams: {
                    values: purchasevoucherType,
                    searchType: "match",
                    allowTyping: true,
                    filterList: true,
                    highlightMatch: true,
                },
                onCellValueChanged: (params) => {
                    const selectedValue = params.data["purchase type"];
                    if (selectedValue !== "No options available") {
                        setUpadateLedger((updateLedger) => [
                            ...updateLedger,
                            {
                                id: params.data.id,
                                purchase_type: selectedValue,
                                srno: params.data.srno,
                            },
                        ]);
                    }
                    const nextRowIndex = params.node.rowIndex + 1;
                    const rowCount = params.api.getDisplayedRowCount();

                    if (nextRowIndex < rowCount) {
                        params.api.setFocusedCell(nextRowIndex, "purchase type");
                    }
                },
            },
            "sales type": {
                headerName: "Voucher Type",
                field: "sales type",
                filter: "agTextColumnFilter",
                floatingFilter: true,

                editable: (params) => params.data.flag !== "SY",
                cellEditor: "agRichSelectCellEditor",

                cellRenderer: (params) => (
                    <div className={`d-flex justify-content-between ${params.value ? "text-primary" : "text-secondary"}`}>
                        <span>{params.value || "Select Type"}</span>
                        <span>
                            <DownOutlined />
                        </span>
                    </div>
                ),
                cellEditorParams: {
                    values: purchasevoucherType,
                    searchType: "match",
                    allowTyping: true,
                    filterList: true,
                    highlightMatch: true,
                },
                onCellValueChanged: (params) => {
                    const selectedValue = params.data["sales type"];
                    console.log("selectedValue", selectedValue);

                    if (selectedValue !== "No options available") {
                        setUpadateLedger((updateLedger) => [
                            ...updateLedger,
                            {
                                id: params.data.id,
                                sales_type: selectedValue,
                                srno: params.data.srno,
                            },
                        ]);
                    }
                    const nextRowIndex = params.node.rowIndex + 1;
                    const rowCount = params.api.getDisplayedRowCount();

                    if (nextRowIndex < rowCount) {
                        params.api.setFocusedCell(nextRowIndex, "sales type");
                    }
                },
            },
            vchtype: {
                headerName: "Type",
                field: "vchtype",
                filter: "agTextColumnFilter",
                floatingFilter: true,

                editable: (params) => params.data.flag !== "SY",
                cellEditor: "agRichSelectCellEditor",

                cellRenderer: (params) => (
                    <div className={`d-flex justify-content-between ${params.value ? "text-primary" : "text-secondary"}`}>
                        <span>{params.value || "Select Type"}</span>
                        <span>
                            <DownOutlined />
                        </span>
                    </div>
                ),
                cellEditorParams: {
                    values: typelist,
                    searchType: "match",
                    allowTyping: true,
                    filterList: true,
                    highlightMatch: true,
                },
                onCellValueChanged: (params) => {
                    const selectedValue = params.data.vchtype;
                    setUpadateLedger((updateLedger) => [
                        ...updateLedger,
                        {
                            id: params.data.id,
                            type: selectedValue,
                            srno: params.data.srno,
                        },
                    ]);
                    const nextRowIndex = params.node.rowIndex + 1;
                    const rowCount = params.api.getDisplayedRowCount();

                    if (nextRowIndex < rowCount) {
                        params.api.setFocusedCell(nextRowIndex, "vchtype");
                    }
                },
            },

            name: {
                headerName: "Name",
                field: "name",
                menuTabs: [],
                tooltipValueGetter: (p) => p.value,
                tooltipShowDelay: 0,
            },
            "invoice date": {
                headerName: "Invoice Date",
                field: "invoice date",
                menuTabs: [],
                cellRenderer: (params) => {
                    const disableRowid = [];
                    const allRowData = [];
                    params.api.forEachNode((node) => {
                        let data = node.data;
                        if (allRowData.includes(data["reference no"] || data["supplier inv no"])) {
                            disableRowid.push(data.id);
                        } else {
                            allRowData.push(data["reference no"] || data["supplier inv no"]);
                        }
                    });
                    if (!disableRowid.includes(params.data.id) || !fileType) {
                        return params.value;
                    }
                },
            },
            amount: {
                headerName: "Amount",
                field: "amount",
                filter: "agTextColumnFilter",
                floatingFilter: true,

                menuTabs: [],
                headerClass: "header-center",
                cellStyle: { textAlign: "center", letterSpacing: "0.05em" },

                cellRenderer: (params) => (
                    <div className={`${params.data.debit > 0 ? "text-danger" : "text-success"}`}>
                        <span>{params.value.toFixed(2) || "0.00"}</span>
                    </div>
                ),
            },
            rate: {
                headerName: "Rate",
                field: "rate",
                menuTabs: [],
                headerClass: "header-center",
                cellStyle: { textAlign: "center", letterSpacing: "0.05em" },
            },
            cgst: {
                headerName: "CGST",
                field: "cgst",
                menuTabs: [],
                headerClass: "header-center",
                cellStyle: { textAlign: "center", letterSpacing: "0.05em" },
            },
            igst: {
                headerName: "IGST",
                field: "igst",
                menuTabs: [],
                headerClass: "header-center",
                cellStyle: { textAlign: "center", letterSpacing: "0.05em" },
            },
            sgst: {
                headerName: "SGST",
                field: "sgst",
                menuTabs: [],
                headerClass: "header-center",
                cellStyle: { textAlign: "center", letterSpacing: "0.05em" },
            },
            quantity: {
                headerName: "Quantity",
                field: "quantity",
                menuTabs: [],
                headerClass: "header-center",
                cellStyle: { textAlign: "center", letterSpacing: "0.05em" },
            },
            "reference no": {
                headerName: "Reference No",
                field: "reference no",
                menuTabs: [],
                headerClass: "header-center",
                cellStyle: { textAlign: "center", letterSpacing: "0.05em" },
            },
            "supplier inv no": {
                headerName: "Supplier Inv No",
                field: "supplier inv no",
                menuTabs: [],
                headerClass: "header-center",
                cellStyle: { textAlign: "center", letterSpacing: "0.05em" },
            },

            roundoffvalue: {
                headerName: "Round off",
                field: "roundoffvalue",
                menuTabs: [],
                headerClass: "header-center",
                cellStyle: { textAlign: "center", letterSpacing: "0.05em" },
                valueFormatter: (params) => {
                    return params.value ? params.value.toFixed(2) : "--";
                },
            },

            "total amount": {
                headerName: "Total Amount",
                headerComponentParams: {
                    template: (function () {
                        const hasValidRow = rowData.some((row) => {
                            const { rate, cgst, sgst, igst, ["total amount"]: totalAmount } = row;
                            return (rate + cgst + sgst + igst).toFixed(2) != totalAmount;
                        });

                        // Generate header template
                        return `
                          <span class="ag-header-cell-label">
                            <span class="ag-header-cell-text">Total</span>
                            ${
                                hasValidRow
                                    ? `
                              <span
                                class="custom-icon"
                                onclick="handleIconClick('party_ac_name')"
                                style="margin-left: 8px; cursor: pointer;"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                  fill="#FFA500"
                                  width="16"
                                  height="16"
                                  aria-hidden="true"
                                >
                                  <path
                                    d="M12 2c-.41 0-.79.25-.93.63L1.24 20.36A1.01 1.01 0 0 0 2 22h20c.71 0 1.18-.79.69-1.38L12.93 2.63A1 1 0 0 0 12 2zm0 15a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3zm1-10h-2v6h2V7z"
                                  />
                                </svg>
                              </span>
                            `
                                    : ""
                            }
                          </span>
                        `;
                    })(),
                },

                field: "total amount",
                menuTabs: [],
                headerClass: "header-center",
                cellStyle: { textAlign: "center", letterSpacing: "0.05em" },

                valueFormatter: (params) => {
                    return params.value ? params.value.toFixed(2) : "0.00";
                },

                cellRenderer: ({ data, value }) => {
                    const totalAmount = (data.rate + data.cgst + data.sgst + data.igst).toFixed(2);
                    const isValid = totalAmount == data["total amount"];

                    return (
                        <div className={`${isValid ? "" : "text-danger"}`}>
                            <span>{value?.toFixed(2) || "0.00"}</span>
                        </div>
                    );
                },
            },

            description: {
                headerName: "Description",
                field: "description",
                filter: "agTextColumnFilter",
                floatingFilter: true,

                menuTabs: [],
                wrapText: true,
                autoHeight: true,
                tooltipValueGetter: (p) => p.value,
                tooltipShowDelay: 0,
            },
        };

        const flexRules = (field) => {
            if (pathWithoutSlash === "banking") {
                if (["Sr No"].includes(field)) return 0.5;
                if (["date"].includes(field)) return 0.5;
                if (["description"].includes(field)) return 2;
                if (["ledger"].includes(field)) return 3;
            } else if (pathWithoutSlash === "sales/excel") {
                if (["Sr No"].includes(field)) return 0.7;
                if (["invoice date"].includes(field)) return 0.55;
                if (["place of supply", "state of supply"].includes(field)) return 0.45;
                if (["party ac name", "name of item"].includes(field)) return 1.2;
                if (["reference no", "sgst", "cgst", "igst", "quantity"].includes(field)) return 0.35;
                if (["total amount"].includes(field)) return 0.34;
                if (["rate", "amount"].includes(field)) return 0.35;
                if (["sgst ledger", "cgst ledger", "igst ledger", "roundoffvalue"].includes(field)) return 0.55;
            } else if (pathWithoutSlash === "purchase") {
                if (["Sr No"].includes(field)) return 0.7;
                if (["invoice date", "supplier inv no"].includes(field)) return 0.55;
                if (["place of supply", "state of supply"].includes(field)) return 0.45;
                if (["party ac name", "name of item"].includes(field)) return 1.2;
                if (["reference no", "sgst", "cgst", "igst", "quantity"].includes(field)) return 0.35;
                if (["total amount"].includes(field)) return 0.5;
                if (["rate", "amount"].includes(field)) return 0.5;
                if (["sgst ledger", "cgst ledger", "igst ledger", "roundoffvalue"].includes(field)) return 0.55;
            }

            return 1;
        };

        const wantedColumns = {
            banking: ["Sr No", "amount", "date", "description", "ledger", "vchtype"],
            "sales/excel": [
                "amount",
                "cgst",
                "igst",
                "invoice date",
                "name of item",
                "party ac name",
                "place of supply",
                "quantity",
                "rate",
                "reference no",
                "sales type",
                "sales ledger",
                "sgst",
                "state of supply",
                "roundoffvalue",
                "total amount",
                "sgst ledger",
                "cgst ledger",
                "igst ledger",
            ],
            purchase: [
                "supplier inv no",
                "invoice date",
                "purchase type",
                "party ac name",
                "place of supply",
                "purchase ledger",
                "name of item",
                "quantity",
                "rate",
                "amount",
                "sgst",
                "cgst",
                "igst",
                "roundoffvalue",
                "total amount",
                "gst no",
                "sgst ledger",
                "cgst ledger",
                "igst ledger",
            ],
            "item/excel": [
                "applicable date",
                "category",
                "cess tax",
                "description",
                "gst applicable",
                "hsnsac",
                "integrated tax",
                "name",
                "quantity",
                "rate",
                "setalter gst details",
                "taxability",
                "type of supply",
                "under",
                "unit",
            ],
            autosale: [
                "Sr No",
                "flag for item",
                "itemname",
                "party name",
                "profit",
                "purchase from",
                "purchase to",
                "qty",
                "sales from",
                "sales to",
                "salesrate",
                "srno",
                "synced date",
                "totalamount",
                "user master id",
                "vch no",
                "voucher type",
            ],
            autoexpenses: ["Sr No", "amount", "date", "expenses", "remark"],
        };

        const wantedCols = wantedColumns[pathWithoutSlash];

        // let columns = Object.keys(input[0]).map((field) => {
        //     const formattedField = field.replace(/_/g, " ").toLowerCase();
        //     const upperCaseField = field.replace(/\b\w/g, (char) => char.toUpperCase());
        //     const flex = flexRules(field);
        //     console.log("upperCaseField", columnConfig[formattedField]);
        //     console.log("upperCaseField", columnConfig[formattedField]);

        //     return columnConfig[formattedField]
        //         ? { ...columnConfig[formattedField], flex, sortable: true }
        //         : { headerName: upperCaseField, field, flex, sortable: true };
        // });

        let columns = Object.keys(input[0]).map((field) => {
            const formattedField = field.replace(/_/g, " ").toLowerCase();
            return {
                ...columnConfig[formattedField],
                flex: flexRules(field),
                sortable: true,
                field,
            };
        });
        // .filter(({ field }) => input.some((row) => row[field] != null && row[field] !== ""));

        columns = columns.filter((column) => wantedCols?.includes(column.field.replace(/_/g, " ").toLowerCase()));
        columns.push({
            headerName: "Action",
            field: "action",
            flex: 0.4,
            headerClass: "header-center",
            cellStyle: { textAlign: "center" },
            cellRenderer: (params) => {
                const { data } = params;
                const { flag, id } = data;
                const rolePath = `/${oldPathWithoutSlash}`;
                const isDangerRole = role[rolePath] ? ["D", "F"].includes(role[rolePath]) : true;
                const isFlagSY = flag === "SY";
                const deleteButtonClass = [
                    "TransactionsTable-deleterow",
                    "Table-deleterow",
                    isDangerRole && !isFlagSY ? "text-danger" : "text-secondary",
                    isFlagSY ? "text-secondary" : "",
                ]
                    .filter(Boolean)
                    .join(" ");

                const handleDeleteClick = (event) => {
                    if (!isFlagSY) {
                        event.stopPropagation();
                        deleteSingleRow(data);
                    }
                };

                return (
                    <p className="action_btn_Div">
                        <Button
                            style={{ height: "0px", padding: "12px 0 0 0" }}
                            className={deleteButtonClass}
                            type="danger"
                            onClick={handleDeleteClick}
                            disabled={role && !isDangerRole}
                        >
                            <DeleteOutlined />
                        </Button>
                        {pathnamecapital === "Banking" && !isFlagSY && (
                            <Button
                                className="bg-transparent splitOpen-btn"
                                type="text"
                                icon={<PlusCircleOutlined />}
                                onClick={() => handleOpenSlpitMenu(id)}
                            />
                        )}
                    </p>
                );
            },
        });

        columns.unshift({
            headerName: "Sr No",
            suppressFilter: true,
            flex: 0.3,
            menuTabs: [],
            valueGetter: (params) => params.node.rowIndex + 1, // Add serial number starting from 1
        });

        columns.unshift({
            headerName: "",
            field: "",
            suppressFilter: true,
            headerCheckboxSelection: true,
            headerClass: "header-center",
            flex: 0.2,
            menuTabs: [],
            checkboxSelection: true,
            showDisabledCheckboxes: true,
        });

        return columns;
    };

    const isRowSelectable = useCallback((params) => {
        return !!params.data && params.data.flag !== "SY";
    }, []);

    const deleteSingleRow = (rowData) => {
        axios
            .post(`${process.env.REACT_APP_API_URL}/deleteData`, {
                userId: validatedProfile.userType === "S" ? validatedProfile.parentid : validatedProfile.userid,
                companyId: validatedProfile.companyId,
                tablename: pathPlusData,
                deleteID: [{ id: rowData.id }],
            })
            .then((response) => {
                if (response.status === 200) {
                    fetchSubTableData();
                    viewAll();
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    const [isPartyErrorState, setIsPartyErrorState] = useState(false);
    const [isItemErrorState, setIsItemErrorState] = useState(false);
    const [newRowData, setNewRowData] = useState([]);

    useEffect(() => {
        setNewRowData(rowData);
    }, [rowData]);

    window.handleIconClick = (columnname) => {
        let newData;

        if (columnname === "party_ac_name") {
            newData = isPartyErrorState ? rowData : ledgerNameError;
            setNewRowData(newData);
            setIsPartyErrorState(!isPartyErrorState); // Toggle the state
        } else if (columnname === "name_of_item") {
            newData = isItemErrorState ? rowData : stockitemNameError;
            setNewRowData(newData);
            setIsItemErrorState(!isItemErrorState); // Toggle the state
        }

        // console.log(`Icon clicked for: ${columnname}`, newData);
    };
    const gridWidth =
        pathWithoutSlash === "banking"
            ? "100%"
            : pathWithoutSlash === "sales/excel"
            ? "120%"
            : pathWithoutSlash === "purchase"
            ? "110%"
            : "100%";
    return (
        <>
            <div className="TransactionsTable-table" style={{ width: "100%", overflow: "auto" }}>
                <div
                    className="ag-theme-balham ag-TransactionsTable"
                    style={{ height: "calc(100vh - 230px)", fontSize: "12px", width: gridWidth }}
                >
                    <AgGridReact
                        suppressHorizontalScroll={false}
                        gridOptions={gridOptions}
                        isRowSelectable={isRowSelectable}
                        columnDefs={colDefs}
                        getRowStyle={getRowStyle}
                        rowData={newRowData}
                        suppressRowClickSelection={true}
                        rowSelection="multiple"
                        floatingFilter={false}
                        overlayNoRowsTemplate="<span style='padding: 10px; border: 1px solid #ccc; background: #f5f5f5;'>No data available</span>"
                    />
                </div>
            </div>
        </>
    );
};

export default withProfile(AgGridSubTable);
